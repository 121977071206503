
/*global jQuery, CodeMirror */
var mirror = (function ($) {
    'use strict';

    var commandField,
        $codeMirrorInstances;

    /**
     * Generates CodeMirror instances from all appropriate text areas.
     * Sets the command field;
     */
    function init() {
        $('textarea').each(function () {
            if ($(this).hasClass('code-snippet') || typeof $(this).data('language') !== 'undefined') {
                var editor = CodeMirror.fromTextArea($(this)[0], getOptions($(this)));
                if ($(this).hasClass('js-command-field')) {
                    commandField = editor;
                }
            }
        });
        cacheDom();
        bindEvents();
    }

    function cacheDom() {
        $codeMirrorInstances =  $('.CodeMirror');
    }

    function bindEvents() {
        //
    }

    function refresh() {
        $codeMirrorInstances.each(function () {
            $(this)[0].CodeMirror.refresh();
        });
    }

    function getEditor() {
        return commandField;
    }

    /**
     * Returns the options of the desired Code Mirror instance
     *
     * @returns {{}}
     */
    function getOptions($element) {

        var readOnly = ($element.hasClass('code-snippet')),
            language = (typeof $element.data('language') !== 'undefined') ?
                $element.data('language') : 'processing';

        var mixedMode = {
            name: "htmlmixed",
            scriptTypes: [{
                matches: /\/x-handlebars-template|\/x-mustache/i,
                mode: null
            },
                {
                    matches: /(text|application)\/(x-)?vb(a|script)/i,
                    mode: "vbscript"
                }]
        };

        var options = {};
        options.matchBrackets = true;
        options.scrollbarStyle = "null";
        options.tabSize = 2;
        options.lineWrapping = true;

        if ( language === 'html' ) {
            options.mode = mixedMode;
            options.matchTags = { bothTags: true };
            options.extraKeys = { "Ctrl-J": "toMatchingTag", "Ctrl-Space": "autocomplete" };
        }

        if ( language === 'processing' || language === 'javascript' ) {
            options.mode = "javascript";
            options.extraKeys = { "Ctrl-Space": "autocomplete" };
        }

        if ( language === 'json' ) {
            options.mode = 'application/json';
        }

        options.theme = 'youplusplus';

        if ( readOnly ) {
            options.readOnly = true;
            options.viewPortMargin = Infinity;
        } else {
            options.lineNumbers = true;
        }

        return options;
    }

    return {
        init: init,
        refresh: refresh,
        getEditor: getEditor
    };

})(jQuery);




