/*global jQuery, lang, tour, trace, code, instruction */
var feedback = (function ($, lang) {
    "use strict";

    var $totalPoints,
        $flashWrapper,
        $flash;

    function init() {
        cacheDom();
        bindEvents();

        if ( YPP.points !== undefined && YPP.points.points !== false ) {
            showPointsScored(YPP.points);
        }

        if ( YPP.flash !== undefined ) {
            flash(YPP.flash.message, YPP.flash.title, YPP.flash.type);
        }
    }

    function cacheDom() {
        $flashWrapper = $('.js-flash-wrapper');
        $flash = $flashWrapper.find('.js-flash');

        $totalPoints = $('#total-points');
    }

    function bindEvents() {
        $(document).on("click", function (event) {
            var targetId = $(event.target).attr('id');
            if ( !(targetId === 'btn-submit' || targetId === 'btn-submit-landing') ) {
                // needed because event target becomes course-page when clicks are handled in sketch
                if ( progress.courseId !== 2 ) {
                    hide();
                }
            }
        });

        $totalPoints.on('shown.bs.popover', function () {
            setTimeout(function () {
                $totalPoints.popover('hide');
            }, 3500);
        });
    }

    function hide() {
        $('[data-toggle="popover"]').popover('hide');
        $flashWrapper.each(function () {
            $(this).removeClass('visible');
        });
    }

    function flash(message, title, type) {

        hide();

        title = typeof title !== 'undefined' ? title : lang.trans('flash-success-title');
        type = typeof type !== 'undefined' ? type : 'success';

        var img = {
            'success': '/assets/img/kids-success.png',
            'wrong': '/assets/img/kids-error.png',
            'fail': "/assets/img/robo-error.png"
        };

        $flash.data('bs.popover', null).data('content', message).attr('title', title).attr('src', img[type]);

        $flash.popover('show');

        $flash.closest('.js-flash-wrapper').addClass('visible');
    }

    function fresh(executed, error) {

        if ( tour.isRunning() ) {

            if (tour.type() === 'onboarding' ) {
                return;
            }

            var tourPopover = $('.tour-popover');
            if ( !tourPopover.find('.tour-text').find('.feedback-tour').length ) {
                tourPopover.find('.tour-text').append('<br><br><p class="feedback-tour font-primary">' + lang.trans('tour-feedback-wrong') + '</p>');
            }
            setTimeout(function () {
                tour.previous();
            }, 1500);

            return;
        }

        if ( !executed && typeof error !== 'undefined' ) {
            try {
                error = trace.analyze(error);
            } catch ( error ) {
                console.log('Errorception: Exception while tracing error: ' + error);
            }
        }

        if ( !executed && error.line ) {
            code.setLineError(error.line);
        }

        var type = (!executed) ? 'fail' : 'wrong',
            message = function() {
                if ( typeof error !== 'undefined' && error.hasOwnProperty('feedback') ) {
                    return error.feedback;
                }
                if ( executed ) {
                    return lang.trans('feedback-wrong');
                }
                return lang.trans('feedback-invalid-generic');
            },
            title = lang.trans('feedback-title');

        return flash(message(), title, type);
    }

    function showPointsScored(data) {

        var negative = (data.points < 0 ),
            color = (negative) ? 'negative' : 'positive',
            start = (negative) ? 'Oops' : 'YEAH!',
            title = (negative) ? lang.trans('points-feedback-title-negative') : lang.trans('points-feedback-title-positive'),
            img = (negative) ? '/assets/img/coderobo-results-neg.gif' : '/assets/img/coderobo-results.gif';

        /* Hide the current popover first, if it is still visible */
        var popover = $totalPoints.data('bs.popover');
        if ( popover && popover.tip() && popover.tip().is(':visible') ) {
            popover.hide();
        }

        $totalPoints.data('bs.popover', null).popover({
            'placement': 'bottom',
            'html': true,
            'container': 'body',
            'content': function () {
                return "<div class='row'>" +
                    "<div class='col-md-4 tour-pic'>" +
                    "<img src=" + img + " alt='tour YOU++ publish!'>" +
                    "</div>" +
                    "<div class='col-md-8 tour-text'>" +
                    "<p>" + lang.trans('points-feedback-start', {
                        'start': start,
                        'action': data.phrase
                    }) + "<br><span class='points-scored " + color + "'>" + data.points + " " + lang.trans('points') + "!</span></p>" +
                    "</div>" +
                    "</div>";
            },
            'template': '<div class="popover points-popover" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>',
            'title': function () {
                return title;
            }
        });

        $totalPoints.html(data.total_points);
        $('.js-total-points').html(data.total_points);
        $totalPoints.popover('show');

        YPP.points = undefined;
    }

    // public API
    return {
        init: init,
        hide: hide,
        fresh: fresh,
        flash: flash,
        showPointsScored: showPointsScored
    };

})
(jQuery, lang);
