/* This file contains the language lookup object for text generated in Javascript
 * It adheres to the revealing module pattern and only makes the trans() function available in public API
 ========================================================================================================= */

/*global YPP */
var lang = (function (YPP) {
    "use strict";

    var en = {
        'tour-next': 'Next',
        'tour-prev': 'Prev',
        'tour-1-title': 'Tour de YOU++',
        'tour-1-content': 'Whooop whoooop! I\'ll help you with the first task!',
        'tour-2-title': 'Instructions',
        'tour-2-content': 'The instructions tell you what to do to reach the next level. Read them carefully, and solve the task in the blue box.',
        'tour-3-title': 'Command Field',
        'tour-3-content': 'In this command field you can write your code! Remember the instruction? <br><br><b>write: point(200,100);</b>',
        'tour-4-title': 'Run your Code',
        'tour-4-content': 'Ready to run your code? Click here to make the computer read your commands.',
        'tour-5-title': 'Results',
        'tour-5-content': 'You can see the results of your code here right away! You see that point right there? You coded that! Aaaaaawesome, right?!',
        'tour-6-title': 'Score Points',
        'tour-6-content': 'And you just earned some points for completing your first task. Yeeeehhaaaaaa!',
        'tour-7-title': 'Next Task',
        'tour-7-content': 'Let\'s click next and go on with the next task.',
        'tour-8-title': 'Tour de YOU++',
        'tour-8-content': 'Whooop whoooop! Now that you know your way around a little bit, let me tell you some more.',
        'tour-9-title': 'Hint',
        'tour-9-content': 'Stuck? You can click the hint button to get some help.',
        'tour-10-title': 'Reset the Code',
        'tour-10-content': 'You can always reset your code back to the starting code with the reset button.',
        'tour-11-title': 'Progress',
        'tour-11-content': 'You can check your progress here. You are already at task :part.',
        'tour-12-title': 'Publish',
        'tour-12-content': 'At the end of the course, you can publish your creations and show it to your friends and family!',
        'tour-13-title': 'Let\'s go!',
        'tour-13-content': 'Awesome! Let\'s continue!',

        'tour-onboarding-1-title': 'Welcome!',
        'tour-onboarding-1-content': 'Hey, I\'m Lina! Let\'s together program your profile picture!',
        'tour-onboarding-2-title': 'The code',
        'tour-onboarding-2-content': 'In a minute, you can type your own code here. I have already coded a <i>black background</i> and the <i>robot cody</i>.',
        'tour-onboarding-3-title': 'The profile picture',
        'tour-onboarding-3-content': 'Here you see what the computer draws with your code. Look, there\'s Cody!',
        'tour-onboarding-4-title': 'Your first code',
        'tour-onboarding-4-content': 'Now it\'s your turn to program! <i class=\"emph\">Change the word</i> <i class=\'string\'>black</i> <i class=\"emph\">into</i> <i class=\'string\'>red</i><i class=\"emph\">.</i>',
        'tour-onboarding-5-title': 'Run your code',
        'tour-onboarding-5-content': 'With this button you can let the computer execute your code. <i class=\"emph\">Click on Run my Code.</i>',
        'tour-onboarding-6-title': 'Your profile picture',
        'tour-onboarding-6-content': 'Awesome! You just programmed your own profile picture!',
        'tour-onboarding-7-title': 'Your name',
        'tour-onboarding-7-content': 'Now we just need to know your name. <i class=\"emph\">Type your name below.</i>',
        'tour-onboarding-8-title': 'Save',
        'tour-onboarding-8-content': 'Great! You can still change your profile picture - for example by changing the robot. If you\'re ready, click on <i class=\"emph\">Save</i> below.',
        'tour-onboarding-8-content-name': 'Great, <i class=\'emph\'>:name</i>! You can still change your profile picture - for example by changing the robot. If you\'re ready, click on <i class=\"emph\">Save</i> below.',

        'tour-feedback-right': 'That\'s it. Well done. Click Next.',
        'tour-feedback-wrong': 'Oops, not quite right. Go back and try again.',

        'feedback-title': 'Oops, something went wrong.',
        'feedback-wrong': 'The computer <i class=\"emph\">understands</i> your code, but it is <i>not the answer</i> to the task. Try again!',
        'feedback-invalid-generic': 'The computer <i>doesn\'t understand</i> your code. Check if you put all the correct brackets, commas, etc.',

        'points-feedback-title-positive': 'You scored points!',
        'points-feedback-title-negative': 'You lost points.',
        'points-feedback-start': ':start You :action. You scored ',
        'points': 'points',

        'hint-popover-title': 'Watch out!',
        'hint-popover-content': '<p>The solution costs <span class="points-scored negative">100 points</span></p>',

        'hint-btn-popover-title': 'Need a hint?',
        'hint-btn-popover-content': '<p>You can always click on <b>Get a hint</b> to get some help</p>',

        "canvas-game-over": 'GAME OVER',
        "canvas-game": 'GAME',
        "canvas-over": 'OVER',
        "canvas-try-again": 'Try Again!',
        "canvas-mission-complete": 'MISSION COMPLETE',
        "canvas-mission": 'MISSION',
        "canvas-complete": 'COMPLETE',
        "canvas-next-mission": 'Go to Next Level!',
        "canvas-fuel-left": 'Fuel left: :fuel',
        "canvas-oxygen-left": 'Oxygen left: :fuel',
        "canvas-check-highscore": "Check your highscore below!",

        "error-parameter-amount" : "The <i>:command</i> command needs <i>:amount parameter(s)</i> between the brackets.<br><br>For example: <i class=\'emph\'>:example</i>",
        "error-parameter-type" : "The <i>:command</i> command needs <i>:type</i> as <i>parameter :parameter</i>.<br><br>For example: <i class=\'emph\'>:example</i>",
        "error-parameter-range" : "The <i>:command</i> command needs <i>:type</i> between <i>:low and :high</i> as <i>parameter :parameter</i>.<br><br>For example: <i class=\'emph\'>:example</i>",
        "error-select": "The <i>:command</i> command doesn't have the correct input for <i>parameter :parameter</i>. You have to choose one of the following: <i class=\'emph\'>:options</i>.<br><br>For example: <i class=\'emph\'>:example</i>",
        "error-reference" : 'The computer does not know what <i>:command</i> at <i>line :line</i> is.',
        "error-reference-hint": 'The computer does not know what <i>:command</i> at <i>line :line</i> is. Did you mean <i class=\"emph\">:hint</i>?',
        "error-reference-hint-no-line": 'The computer does not know what <i>:command</i> is. Did you mean <i class=\"emph\">:hint</i>?',
        "error-orientation": 'The <i>:item</i> must be directed either <i class=\"emph\">horizontally</i> or <i class=\"emph\">vertically</i>.<br><br>For example: <i class=\'emph\'>:example</i>',

        "error-type-integer": 'a round number',
        "error-type-string": 'a string between quotation marks',
        "error-type-object": 'an object without quotation marks',

        "onboarding-popover-title": 'Make it your own',
        "onboarding-popover-content": 'Change for example <i>blue</i> into <i class=\"emph\">green</i>, and <i>unknown</i> into <i class=\"emph\">cody</i>.',

        "dashboard-attempts": "Attempts",
        "dashboard-completed": "Correct Answers",
        "dashboard-cheats": "Answers Bought",

        "flash-success-title": 'Success!'
    };

    var nl = {
        'tour-next': 'Okay',
        'tour-prev': 'Vorige',
        'tour-1-title': 'Tour de YOU++',
        'tour-1-content': 'Whooop whoooop! Ik zal je helpen met je eerste opdracht!',
        'tour-2-title': 'Instructies',
        'tour-2-content': 'De instructies helpen je om het volgende level te halen. Lees ze goed! De opdracht in de blauwe box moet je zo doen! Okay?',
        'tour-3-title': 'Commands',
        'tour-3-content': 'In dit veld schrijf je je commands! Het rode betekent dat je daar je iets moet doen. In de instructie stond dat je <b>point(200,100);</b> moet typen. Gedaan?',   // <br><br>schrijf: <b>point(200,100);</b>
        'tour-4-title': 'Run je Code',
        'tour-4-content': 'Klik hier om je code te runnen en de computer je commands te laten lezen.',
        'tour-5-title': 'Resultaat',
        'tour-5-content': 'Daarna kan je hier het resultaat van je code zien. Zie je dat puntje daar? Die heb jij geprogrammeerd! Goed gedaan!!',
        'tour-6-title': 'Punten',
        'tour-6-content': 'En je hebt net punten gescoord voor je eerste opdracht. Yeeeehhaaaaaa!',
        'tour-7-title': 'Volgende Opdracht',
        'tour-7-content': 'Klik op Volgende en ga door naar de volgende opdracht',
        'tour-8-title': 'Tour de YOU++',
        'tour-8-content': 'Whooop whoooop! Dat gaat goed, je bent een natuurtalent.',
        'tour-9-title': 'Hint',
        'tour-9-content': 'Zit je vast? Hier kan je wat hulp krijgen.',
        'tour-10-title': 'Reset je Code',
        'tour-10-content': 'Met de reset knop kun je altijd terug naar de begin code van de opdracht.',
        'tour-11-title': 'Voortgang',
        'tour-11-content': 'Hier zie je je voortgang. Je bent al bij opdracht :part.',
        'tour-12-title': 'Publiceren',
        'tour-12-content': 'Aan het eind van de lessen kun je je code op internet publiceren enaan je vrienden en familie laten zien!',
        'tour-13-title': 'Let\'s go!',
        'tour-13-content': 'Super! Laten we verder gaan!',

        'tour-onboarding-1-title': 'Welkom!',
        'tour-onboarding-1-content': 'Hey, ik ben Lina! Laten we samen een profielfoto voor jou programmeren!',
        'tour-onboarding-2-title': 'De code',
        'tour-onboarding-2-content': 'Hier kun je straks je eigen code typen. Ik heb vast een <i>zwarte achtergrond</i> en de <i>robot cody</i> geprogrammeerd.',
        'tour-onboarding-3-title': 'De profielfoto',
        'tour-onboarding-3-content': 'Hier zie je wat de computer tekent met behulp van de code. Kijk, daar is Cody!',
        'tour-onboarding-4-title': 'Jouw eerste code',
        'tour-onboarding-4-content': 'Nu is het jouw beurt om te programmeren! <i class=\"emph\">Verander het woord</i> <i class=\'string\'>black</i> <i class=\"emph\">in</i> <i class=\'string\'>red</i><i class=\"emph\">.</i>',
        'tour-onboarding-5-title': 'Run je Code',
        'tour-onboarding-5-content': 'Met deze knop kun je de computer je code laten lezen. <i class=\"emph\">Klik op Run mijn Code.</i>',
        'tour-onboarding-6-title': 'Jouw profielfoto',
        'tour-onboarding-6-content': 'Awesome! Je hebt je eigen profielfoto geprogrammeerd!',
        'tour-onboarding-7-title': 'Je naam',
        'tour-onboarding-7-content': 'Nu moeten we alleen nog je naam weten. <i class=\"emph\">Typ hieronder je naam.</i>',
        'tour-onboarding-8-title': 'Opslaan',
        'tour-onboarding-8-content': 'Super! Je kunt je profielfoto nog meer aanpassen - bijvoorbeeld met een andere robot. Als je klaar bent, klik je hieronder op <i class=\"emph\">Opslaan</i>.',
        'tour-onboarding-8-content-name': 'Super, <i class=\'emph\'>:name</i>! Je kunt je profielfoto nog meer aanpassen - bijvoorbeeld met een andere robot. Als je klaar bent, klik je hieronder op <i class=\"emph\">Opslaan</i>.',

        'tour-feedback-right': 'Yes! Goed gedaan. Klik Okay.',
        'tour-feedback-wrong': 'Oops, niet helemaal goed. Ga terug en probeer het nog een keer.',

        'feedback-title': 'Oops, er is iets mis.',
        'feedback-wrong': 'De computer <i class=\"emph\">begrijpt</i> je code, maar het is <i>niet het antwoord</i> op de opdracht.',
        'feedback-invalid-generic': 'De computer <i>begrijpt je code niet</i>. Check of alle haakjes, komma\'s enz. allemaal goed staan.',

        'points-feedback-title-positive': 'Je hebt punten gescoord!',
        'points-feedback-title-negative': 'Je hebt punten verloren.',
        'points-feedback-start': ':start Je :action. Je scoorde ',
        'points': 'punten',

        'hint-popover-title': 'Pas op!',
        'hint-popover-content': '<p>De oplossing kost <span class="points-scored negative">100 punten</span></p>',

        'hint-btn-popover-title': 'Hulp nodig?',
        'hint-btn-popover-content': '<p>Je kunt altijd op <b>Ik wil een hint</b> klikken voor hulp.</p>',

        "canvas-game-over": 'GAME OVER',
        "canvas-game": 'GAME',
        "canvas-over": 'OVER',
        "canvas-try-again": 'Opnieuw!',
        "canvas-mission-complete": 'MISSIE GESLAAGD',
        "canvas-mission": 'MISSIE',
        "canvas-complete": 'GESLAAGD ',
        "canvas-next-mission": 'Volgende Level!',
        "canvas-fuel-left": 'Benzine over: :fuel',
        "canvas-oxygen-left": 'Zuurstof over: :fuel',
        "canvas-check-highscore": "Check je highscore hieronder!",

        "error-parameter-amount" : "Het <i>:command</i> command heeft <i>:amount parameter(s)</i> nodig tussen de haakjes.<br><br>Bijvoorbeeld: <i class=\'emph\'>:example</i>",
        "error-parameter-type" : "Het <i>:command</i> command heeft <i>:type</i> als <i>parameter :parameter</i> nodig.<br><br>Bijvoorbeeld: <i class=\'emph\'>:example</i>",
        "error-parameter-range" : "Het <i>:command</i> command heeft <i>:type</i> tussen <i>:low en :high</i> als <i>parameter :parameter</i> nodig.<br><br>Bijvoorbeeld: <i class=\'emph\'>:example</i>",
        "error-select": "Het <i>:command</i> command heeft niet de goede input voor <i>parameter :parameter</i>.<br><br>Kies uit: <i class=\'emph\'>:options</i>.<br><br>Bijvoorbeeld: <i class=\'emph\'>:example</i>",
        "error-reference" : 'De computer weet niet wat <i>:command</i> op <i>regel :line</i> is.',
        "error-reference-hint": 'De computer weet niet wat <i>:command</i> op <i>line :line</i> is. <br><br>Bedoel je <i class=\"emph\">:hint</i>?',
        "error-reference-hint-no-line": 'De computer weet niet wat <i>:command</i> is. <br><br>Bedoel je <i class=\"emph\">:hint</i>?',
        "error-orientation": 'De <i>:item</i> moet <i class=\"emph\">horizontaal</i> of <i class=\"emph\">verticaal</i> gericht zijn.<br><br>Bijvoorbeeld: <i class=\'emph\'>:example</i>',

        "error-type-integer": 'een rond getal',
        "error-type-string": 'een string tussen aanhalingstekens',
        "error-type-object": 'een object zonder aanhalingstekens',

        "onboarding-popover-title": 'Maak je eigen',
        "onboarding-popover-content": 'Verander bijboorbeeld <i>blue</i> naar <i class=\"emph\">green</i>, en <i>unknown</i> naar <i class=\"emph\">cody</i>.',

        "dashboard-attempts": "Pogingen",
        "dashboard-completed": "Goede Antwoorden",
        "dashboard-cheats": "Antwoorden Gekocht",

        "flash-success-title": 'Succes!'
    };

    var sv = {
        'tour-next': 'Nästa',
        'tour-prev': 'Föregående',
        'tour-1-title': 'Tour de YOU++',
        'tour-1-content': 'Whooop whoooop! Jag hjälper dig med den första uppgiften!',
        'tour-2-title': 'Instruktioner',
        'tour-2-content': 'Instruktionerna berättar vad du ska göra för att nå nästa uppgift. Läs dem noga!',
        'tour-3-title': 'Kommandofältet',
        'tour-3-content': 'I kommandofältet kan du skriva din kod! Kommer du ihåg instruktionerna? <br><br><b>skriv: point(200,100);</b>',
        'tour-4-title': 'Kör din kod',
        'tour-4-content': 'Redo att köra din kod? Klicka här för att få datorn att läsa dina kommandon.',
        'tour-5-title': 'Resultat',
        'tour-5-content': 'Du kan se resultatet av din kod här! Ser du punkten? Du kodade den! Aaaaaawesome, eller hur?!',
        'tour-6-title': 'Poäng',
        'tour-6-content': 'Och du fick dessutom poäng när du slutförde din första uppgift. Yeeeehhaaaaaa!',
        'tour-7-title': 'Nästa uppgift',
        'tour-7-content': 'Klicka på Nästa och gå till nästa uppgift.',
        'tour-8-title': 'Tour de YOU++',
        'tour-8-content': 'Whooop whoooop! Nu ska jag visa er något annat.',
        'tour-9-title': 'Tips',
        'tour-9-content': 'Fastnat? Du kan klicka på tipsknappen för att få hjälp.',
        'tour-10-title': 'Återställ kod',
        'tour-10-content': 'Du kan alltid återställa din kod tillbaka till startkoden.',
        'tour-11-title': 'Framsteg',
        'tour-11-content': 'Du kan se dina framsteg här. Du är redan i uppgift :part.',
        'tour-12-title': 'Publicera',
        'tour-12-content': 'I slutet av kursen kan du publicera din kod och visa den för dina vänner och din familj!',
        'tour-13-title': 'Nu kör vi',
        'tour-13-content': 'Toppen. Nu fortsätter vi!',

        'tour-feedback-right': 'Bra gjort. Klicka på Nästa.',
        'tour-feedback-wrong': 'Oops, inte helt rätt. Gå tillbaka och försök igen.',

        'feedback-title': 'Oops, något gick fel.',
        'feedback-wrong': 'Datorn <i class=\"emph\">förstår</i> din kod, men det är <i>inte rätt lösning</i>.',
        'feedback-invalid-generic': 'Datorn <i>förstår inte</i> din kod. Så att du har alla kommatecken och parenteser som datorn behöver.',

        'points-feedback-title-positive': 'Du fick poäng!',
        'points-feedback-title-negative': 'Du förlorade poäng!',
        'points-feedback-start': ':start Du :action. Du fick ',
        'points': 'poäng',

        'hint-popover-title': 'Akta!',
        'hint-popover-content': '<p>Lösningen kostar <span class="points-scored negative">100 poäng</span></p>',

        'hint-btn-popover-title': 'Fastnat?',
        'hint-btn-popover-content': '<p>Du kan klicka på <b>tipsknappen</b> för att få hjälp.</p>',

        "canvas-game-over": 'GAME OVER',
        "canvas-game": 'GAME',
        "canvas-over": 'OVER',
        "canvas-try-again": 'Försök igen!',
        "canvas-mission-complete": 'UPPDRAG SLUTFÖRT',
        "canvas-mission": 'UPPDRAG',
        "canvas-complete": 'SLUTFÖRT ',
        "canvas-next-mission": 'Nästa level!',
        "canvas-fuel-left": 'Bensin kvar :fuel',
        "canvas-oxygen-left": 'Luft kvar :fuel',
        "canvas-check-highscore": "Kolla din highscore här under!",

        "error-parameter-amount" : "Kommandot <i>:command</i> behöver <i>:amount parameter/rar</i> mellan parenteserna för att fungera.<br><br>Till exempel: <i class=\'emph\'>:example</i>",
        "error-parameter-type" : "Kommandot <i>:command</i> behöver  <i>:type</i> som <i>parameter :parameter</i> .<br><br> Till exempel: <i class=\'emph\'>:example</i>",
        "error-parameter-range" : "Kommandot <i>:command</i> behöver <i>:type</i> mellan <i>:low en :high</i> som <i>parameter :parameter</i> .<br><br>TIll exempel: <i class=\'emph\'>:example</i>",
        "error-select": "Kommandot <i>:command</i> har inte rätt input för <i>parameter :parameter</i>. Du måste välja en av de här: <i class=\'emph\'>:options</i>.<br><br> Till exempel: <i class=\'emph\'>:example</i>",
        "error-reference" : "Datorn vet inte vad <i>:command</i> på <i>rad :line</i> är.",
        "error-reference-hint": 'Datorn vet inte vad <i>:command</i> på <i>rad :line</i> är.  Menade du <i class=\"emph\">:hint</i>?',
        "error-reference-hint-no-line": 'Datorn vet inte vad <i>:command</i> är.  Menade du <i class=\"emph\">:hint</i>?',
        "error-orientation": '<i>:item</i> måste vara positionerat <i class=\"emph\">horizontalt</i> eller <i class=\"emph\">vertikalt</i> .<br><br>Till exempel: <i class=\'emph\'>:example</i>',

        "error-type-integer": 'ett heltal',
        "error-type-string": 'ett "string" i citationstecken',
        "error-type-object": 'ett objekt utan citationstecken',

        "onboarding-popover-title": 'Gör ditt eget',
        "onboarding-popover-content": 'Ändra till exempel <i>blue</i> till <i class=\"emph\">green</i>, och <i>unknown</i> till <i class=\"emph\">cody</i>.',

        "dashboard-attempts": "Försök",
        "dashboard-completed": "Korrekt svar",
        "dashboard-cheats": "Köpt svar",

        "flash-success-title": 'Framgång!!'
    };

    var de = {
        'tour-next': 'Weiter',
        'tour-prev': 'Vorherige',
        'tour-1-title': 'YOU++ Tour',
        'tour-1-content': 'Whooop whoooop! Ich helfe Dir mit der ersten Aufgabe!',
        'tour-2-title': 'Anleitungen',
        'tour-2-content': 'Die Anleitungen helfen Dir, das nächste Level zu erreichen. Lies sie aufmerksam, und löse die Aufgabe in der blauen Box .',
        'tour-3-title': 'Dein Code',
        'tour-3-content': 'In das Befehlsfeld musst Du Deinen Code eingeben. Erinnerst Du Dich an die Aufgabe? <br><br><b>Schreibe: point(200,100);</b>',
        'tour-4-title': 'Code ausführen',
        'tour-4-content': 'Bereit, Deinen Code auszuführen? Klicke hier, damit der Computer Deinen Code liest.',
        'tour-5-title': 'Ergebnisse',
        'tour-5-content': 'Jetzt kannst Du das Resultat Deines Codes direkt hier sehen. Siehst Du den Punkt auf dem kleinen Bildschirm? Das hast Du gemacht! Richtig cool, oder?!',
        'tour-6-title': 'Punkte ergattern',
        'tour-6-content': 'Und natürlich hast Du auch Punkte für die richtige Lösung bekommen. Yeeeehhaaaaaa!',
        'tour-7-title': 'Nächste Aufgabe',
        'tour-7-content': 'Klicke auf "Weiter", um zur nächsten Aufgabe zu gelangen.',
        'tour-8-title': 'YOU++ Tour',
        'tour-8-content': 'Wow! Gut, wie Du Dich auskennst. Aber es gibt noch mehr zu entdecken.',
        'tour-9-title': 'Hinweise',
        'tour-9-content': 'Wenn Du nicht weiterweißt, klicke auf den Hinweis-Button, um Hilfe zu bekommen.',
        'tour-10-title': 'Code zurücksetzen',
        'tour-10-content': 'Mit dem "Zurücksetzen"-Button kannst Du stets Deinen Code zurücksetzen, um noch einmal mit der Aufgabe zu beginnen.',
        'tour-11-title': 'Fortschritt',
        'tour-11-content': 'Hier kannst Du Deinen Fortschritt verfolgen. Du bist bereits bei Aufgabe :part.',
        'tour-12-title': 'Projekte veröffentlichen',
        'tour-12-content': 'Am Ende des Kurses kannst Du Deine Projekte veröffentlichen und sie mit Familie und Freunden teilen!',
        'tour-13-title': 'Los geht\'s!',
        'tour-13-content': 'Super! Lass uns weitermachen!',
        'tour-feedback-right': 'Korrekt! Gut gemacht. Klicke auf "Weiter".',
        'tour-feedback-wrong': 'Oops, nicht ganz richtig. Versuch es nochmal.',

        'tour-onboarding-1-title': 'Willkommen!',
        'tour-onboarding-1-content': 'Hi, Ich bin Lina! Lass uns Dein Profilbild programmieren!',
        'tour-onboarding-2-title': 'Dein Code',
        'tour-onboarding-2-content': 'Hier kannst Du Deinen eigenen Code eingeben. Ich habe schon einen  <i>schwarzen Hintergrund</i> und den <i>Roboter Cody</i> programmiert.',
        'tour-onboarding-3-title': 'Dein Profilbild',
        'tour-onboarding-3-content': 'Hier kannst Du sehen, was der Computer aus Deinem Code macht. Siehst Du? Da ist Cody!',
        'tour-onboarding-4-title': 'Dein erster Code',
        'tour-onboarding-4-content': 'Nun bist Du dran! <i class=\"emph\">Ändere das Wort</i> <i class=\'string\'>black</i> <i class=\"emph\"> zu</i> <i class=\'string\'>red</i><i class=\"emph\">.</i>',
        'tour-onboarding-5-title': 'Deinen Code ausführen',
        'tour-onboarding-5-content': 'Mit dem Button bringst Du den Computer dazu, Deinen Code auszuführen. <i class=\"emph\">Klicke auf Code ausführen.</i>',
        'tour-onboarding-6-title': 'Dein Profilbild',
        'tour-onboarding-6-content': 'Super! Du hast gerade Dein eigenes Profilbild programmiert!',
        'tour-onboarding-7-title': 'Dein Name',
        'tour-onboarding-7-content': 'Jetzt musst Du uns nur noch Deinen Namen verraten. <i class=\"emph\">Gib Deinen Namen hier ein.</i>',
        'tour-onboarding-8-title': 'Speichern',
        'tour-onboarding-8-content': 'Großartig! Du kannst Dein Profilbild immer noch anpassen - indem Du z.B. Deinen Roboter änderst. Sobald Du fertig bist, klicke auf <i class=\"emph\">Speichern</i> below.',
        'tour-onboarding-8-content-name': 'Klasse, <i class=\'emph\'>:name</i>! Du kannst Dein Profilbild immer noch anpassen - indem Du z.B. Deinen Roboter änderst. Sobald Du fertig bist, klicke auf  <i class=\"emph\">Speichern</i> below.',

        'feedback-title': 'Huch, da lief etwas schief.',
        'feedback-wrong': 'Der Computer <i class=\"emph\">versteht</i> Deinen Code, aber es ist <i>nicht die richtige Lösung</i> für die Aufgabe. Versuch\'s nochmal!',
        'feedback-invalid-generic': 'Der Computer kann Deinen Code <i>nicht verstehen</i>. Überprüfe, ob Du alle Kommas und Klammern richtig gesetzt hast.',

        'points-feedback-title-positive': 'Du hast Punkte ergattert!',
        'points-feedback-title-negative': 'Du hast Punkte verloren.',
        'points-feedback-start': ':start Du :action. Du erhälst ',
        'points': 'Punkte',

        'hint-popover-title': 'Aufgepasst!',
        'hint-popover-content': '<p>Die Lösung kostet Dich <span class="points-scored negative">100 Punkte</span></p>',

        'hint-btn-popover-title': 'Hinweis nötig?',
        'hint-btn-popover-content': '<p>Du kannst jederzeit auf den Hinweis-Button klicken, um Hilfe zu bekommen.</p>',

        "canvas-game-over": 'GAME OVER',
        "canvas-game": 'GAME',
        "canvas-over": 'OVER',
        "canvas-try-again": 'Noch einmal!',
        "canvas-mission-complete": 'MISSION COMPLETE',
        "canvas-mission": 'MISSION',
        "canvas-complete": 'COMPLETE',
        "canvas-next-mission": 'Nächstes Level!',
        "canvas-fuel-left": 'Übriger Treibstoff: :fuel',
        "canvas-oxygen-left": 'Übriger Sauerstoff: :fuel',
        "canvas-check-highscore": "Schau Dir Deinen Highscore unten an!",

        "error-parameter-amount" : "Dein Befehl <i>:command</i> benötigt <i>:amount Parameter</i> zwischen den Klammern.<br><br>Zum Beispiel: <i class=\'emph\'>:example</i>",
        "error-parameter-type" : "Dein Befehl <i>:command</i> benötigt <i>:type</i> als <i>Parameter :parameter</i>.<br><br>Zum Beispiel: <i class=\'emph\'>:example</i>",
        "error-parameter-range" : "Dein Befehl <i>:command</i> benötigt <i>:type</i> zwischen <i>:low and :high</i> als <i>Parameter :parameter</i>.<br><br>Zum Beispiel: <i class=\'emph\'>:example</i>",
        "error-select": "Bei deinem Befehl <i>:command</i> stimmt die Eingabe für den <i>Parameter :parameter</i> nicht. Du musst eine der folgenden Optionen auswählen: <i class=\'emph\'>:options</i>.<br><br>Zum Beispiel: <i class=\'emph\'>:example</i>",
        "error-reference" : 'Der Computer weiß nicht, was <i>:command</i> in <i>Zeile :line</i> ist. Findest Du den Fehler?',
        "error-reference-hint": 'Der Computer weiß nicht, was <i>:command</i> in <i>Zeile :line</i> ist. Meintest Du <i class=\"emph\">:hint</i>?',
        "error-reference-hint-no-line": 'Der Computer weiß nicht, was <i>:command</i> ist. Meintest Du <i class=\"emph\">:hint</i>?',
        "error-orientation": '<i>:item</i> müssen entweder <i class=\"emph\">horizontal</i> oder <i class=\"emph\">vertikal</i> ausgerichtet werden.<br><br>Zum Beispiel: <i class=\'emph\'>:example</i>',

        "error-type-integer": 'eine ganze Zahl',
        "error-type-string": 'einen Text zwischen den Anführungszeichen',
        "error-type-object": 'ein Objekt ohne Anführungszeichen',

        "onboarding-popover-title": 'Mach Dir Dein Eigenes!',
        "onboarding-popover-content": 'Verändere zum Beispiel <i>blue</i> zu <i class=\"emph\">green</i>, und <i>unknown</i> zu <i class=\"emph\">cody</i>.',

        "dashboard-attempts": "Lösungsversuche",
        "dashboard-completed": "Korrekte Antworten",
        "dashboard-cheats": "Gekaufte Antworten",

        "flash-success-title": 'Erfolg!'
    };

    var fallbackLocale = 'en';

    var lookup = {
        'en': en,
        'nl': nl,
        'sv': sv,
        'de': de
    };

    var replaceByKey = function (string, data) {

        for ( var key in data ) {
            if ( data.hasOwnProperty(key) ) {
                var value = data[key];
                var replace = ":" + key;
                if ( string.indexOf(replace) > -1 ) {
                    string = string.replace(replace, value);
                }
            }
        }

        return string;
    };

    var trans = function (key, data, language) {

        // Set locale to current Application locale (set in basic layout)
        if ( language === undefined ) {
            language = YPP.locale;
        }

        // If key doesn't exist for current locale, fall back to English.
        if ( lookup[language][key] === undefined ) {
            language = fallbackLocale;
        }

        var translation = lookup[language][key];

        if ( translation === undefined ) {

            return;

            throw { name : 'TranslationError',
                    description : 'Translation for key ' + key + ' not found.' };
        }

        if ( data !== undefined) {
            translation = replaceByKey(translation, data);
        }

        return translation;
    };

    // public API
    return {
        trans: trans
    };

}(YPP));

