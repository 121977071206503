/*global */
var trace = (function () {
    "use strict";

    /**
     * Analyzes the StackTrace error as part of the result.
     * Returns the same result, with any additional information in the error property.
     * TODO: Safari, IE, Firefox support
     *
     * @returns {*}
     * @param error
     */
    function analyze(error) {

        if ( error.name === "ReferenceError" ) {
            error = analyzeReferenceError(error);
        }

        if ( error.name === "ArgumentError" ) {
            error = analyzeArgumentError(error);
        }

        return error;
    }

    /**
     * Analyzes ReferenceErrors, returning the same error with additional information.
     * @param error
     * @returns {*}
     */
    function analyzeReferenceError(error) {

        var string = stackToStringWithMethod(error.stack, "eval");
        error.line = stackStringToPosition(string);

        if ( error.hasOwnProperty('hint') ) {

            var feedback = {
                'line': error.line,
                'command': error.undefined,
                'hint': error.hint
            };

            error.feedback = (error.line) ?
                lang.trans('error-reference-hint',feedback) :
                lang.trans('error-reference-hint-no-line', feedback);

            return error;
        }

        var referenceUndefined = deduceUndefined(error);

        if ( typeof referenceUndefined !== 'undefined' ) {
            error.feedback = lang.trans('error-reference',
                {
                    'line': error.line,
                    'command': referenceUndefined
                });
        }

        return error;
    }

    /**
     * Analyzes ArgumentErrors, returning the same error with, if possible, additional line number information.
     *
     * @param error
     * @returns {*}
     */
    function analyzeArgumentError(error) {
        var string = stackToStringWithMethod(error.stack, "eval");
        error.line = stackStringToPosition(string);
        return error;
    }

    /**
     * Returns the number-th line (zero based) from the stack, ignoring none stack lines.
     *
     * @param stack
     * @param number
     * @returns {*|string}
     */
    function stackToLine(stack, number) {
        var stackLines = stack.split('\n');

        for ( var i = 0; i < stackLines.length; i++ ) {
            if ( stackLines[i].match(/\d+\:\d+/g) !== null ) {
                return stackLines[i + number];
            }
        }

        return stackLines[Math.min(stackLines.length - 1, number)];
    }

    /**
     * Returns the stack string that contains a certain method
     * @param stack
     * @param method
     * @returns {*}
     */
    function stackToStringWithMethod(stack, method) {
        var stackLines = stack.split('\n');

        for ( var i = 0; i < stackLines.length; i++ ) {
            if ( stackLines[i].match(/\d+\:\d+/g) !== null ) {
                if ( stackLines[i].indexOf(method) > -1 ) {
                    return stackLines[i];
                }
            }
        }

        return undefined;
    }

    /**
     * Grabs the last x:y combination in the string
     * @param line
     * @returns {Number}
     */
    function stackStringToPosition(line) {

        if ( typeof line !== 'string' ) {
            return null;
        }

        var infoMatch = line.match(/\d+\:\d+/g),
            lineInfo;

        if ( !infoMatch ) {
            return null;
        }

        lineInfo = infoMatch.pop().match(/\d+/g); // Includes start character number
        return parseInt(lineInfo[0]);
    }

    /**
     * Gets a ReferenceError Stack String and returns what was undefined
     * @returns {undefined}
     * @param message
     */
    function deduceUndefined(message) {
        var regexNotDefined = /([^\s]+) is not defined|\'([^\s]+)\' is undefined/g,
            regexMatch = regexNotDefined.exec(message);

        return (regexMatch) ? regexMatch[1] || regexMatch[2] : undefined;
    }

    return {
        analyze: analyze
    };

})();


function redirectConsole() {
    var generateNewMethod, i, j, cur, old;
    if ( "console" in window ) {

        generateNewMethod = function (oldCallback, methodName) {
            return function () {
                var output, lineInfo,
                    args = Array.prototype.slice.call(arguments, 0),
                    commandStack = (new Error).stack;
                lineInfo = stackToLineNumberAndPlace(commandStack, 2, 2);
                if ( commandStack.split('\n')[1].indexOf('at Console.' + methodName) > 0 ) { // stop recursion?
                    output = '' + args;
                    appendToLoggingElement(clickableLogElement(methodName, output, lineInfo[0], lineInfo[1]));
                }
                Function.prototype.apply.call(oldCallback, console, arguments);
            };
        };

        for ( i = 0, j = consoleMethodNames.length; i < j; i++ ) {
            cur = consoleMethodNames[i];
            if ( cur in console ) {
                old = console[cur];
                consoleMethods[cur] = old;
                console[cur] = generateNewMethod(old, cur);
            }
        }
    }
}

function onException(ex) {
    var stackLines = ex.stack.split("\n"), text = '', i, countEval, lineInfo, atMethod;
    for ( i in stackLines ) {
        countEval = (stackLines[i].match(/eval/g) || []).length;
        if ( i == 0 ) {
            text += stackLines[i] + "\n";
        } else {
            atMethod = stackLines[i].match(/(at\s\S+)\s\(/)[1];
            if ( atMethod === 'at eval' ) {
                atMethod = 'Editor';
            }
            text += atMethod + ' (' +
                stackLines[i].match(/\d+\:\d+/g)[1] + ')\n';
        }
        if ( countEval === 2 ) // Should be 'at eval (eval at Submitcode'. Works even when eval is called in course
            break;
    }
    var linesInfo = stackToLineNumberAndPlace(ex.stack, 1, i);
    appendToLoggingElement(clickableLogElement('error', text, linesInfo[0], linesInfo[1]));
    console.error(ex);
}

function resetConsole() {
    var i, j, cur;
    if ( "console" in window ) {
        for ( i = 0, j = consoleMethodNames.length; i < j; i++ ) {
            cur = consoleMethodNames[i];
            if ( cur in console ) {
                console[cur] = consoleMethods[cur];
            }
        }
    }
}

function appendToLoggingElement(logElement) {
    document.getElementById('console').appendChild(logElement);
    document.getElementById('console').appendChild(document.createElement('br'));
}

function consoleElementOnHover(over, el) {
    var lineNumbers = el.getAttribute('lineNumbers').split(','), lineNumber,
        backgroundColor = methodColors[el.getAttribute('method')] || methodColors['background'];

    for ( var i = 0; i < lineNumbers.length; i++ ) {
        lineNumber = parseInt(lineNumbers[i]);
        var lineElement = $('.CodeMirror-code').eq(1).children().eq(lineNumber - 1).find('span').eq(0);
        if ( over ) {
            $(lineElement).css('background-color', backgroundColor);
        } else {
            $(lineElement).css('background-color', '');
        }
    }
}

function consoleElementOnClick(el) {
    var lineNumbers = el.getAttribute('lineNumbers').split(','),
        places = el.getAttribute('places').split(',');
    var lineNumber = parseInt(lineNumbers[0]); // First number shows the actual error
    var place = parseInt(places[0]); // First number shows the actual error
    setCursorInEditor(jsEditor, lineNumber - 1, place - 1);
}

// Zero based
function setCursorInEditor(editor, lineNumber, place) {
    editor.focus();
    editor.setCursor({ line: lineNumber, ch: place });
    editor.addLineClass(lineNumber, 'background', 'line-error');
}

function clickableLogElement(method, text, lineNumbers, places) {
    var btn = document.createElement('button');
    var textLines = text.split("\n");
    for ( var i in textLines ) {
        btn.appendChild(document.createTextNode(textLines[i]));
        btn.appendChild(document.createElement('br'));
    }
    btn.removeChild(btn.lastChild);
    btn.className = "console_clickable";
    btn.setAttribute('lineNumbers', lineNumbers);
    btn.setAttribute('places', places);
    btn.setAttribute('method', method);
    btn.setAttribute('onmouseover', 'consoleElementOnHover(true, this)');
    btn.setAttribute('onmouseout', 'consoleElementOnHover(false, this)');
    btn.setAttribute('onclick', 'consoleElementOnClick(this)');
    btn.style.color = methodColors[method] || methodColors['default'];
    return btn;
}

function passOnJsEditor(editor) {
    jsEditor = editor;
}


function oldAnalyze() {
    if ( result.error.name === "Custom Error" ) {
        result.error.custom = result.error.message;
        return result;
    }

    var e = result.error.stack;
    var browserinfo = get_browser_info();
    var regexNotDefined = /([^\s]+) is not defined|\'([^\s]+)\' is undefined/g;
    var pjs = Processing.getInstanceById('processing-canvas');
    var sourceCode = pjs.externals.sketch.sourceCode;

    console.log(pjs.externals.sketch, sourceCode);

    // Note that we rely here on the fact that this string
    // is indeed the last part before the user's code
    var priorCode = sourceCode.split('$p.strokeWeight(4);')[0];
    var userStartLine = priorCode.split(/\r\n|\r|\n/).length;
    // Chrome and Opera count one too many in the stack TODO
    if ( browserinfo.name !== 'Chrome' && browserinfo.name !== 'Opera' ) userStartLine--;
    var stackLine = stackStringToLineNumberAndPlace(stackToLine(e.stack, 0));
    // -1 is returned for the line number if nothing is found
    if ( stackLine[0] === -1 ) return result;
    // Consecutive empty lines / comments are counted as 1
    var consecEmptyCommentLines = 0;
    var userCode = code.get().split('\n');
    var estimatedLine = sourceCode.split('\n').length; // Start highest
    function emptyOrComment(line) {
        return line.trim().length == 0 || line.trim().indexOf('//') == 0;
    }

    for ( var i = 0; i < userCode.length; i++ ) {
        if ( i > 0 && emptyOrComment(userCode[i]) &&
            emptyOrComment(userCode[i + 1]) ) consecEmptyCommentLines++;
        estimatedLine = stackLine[0] - userStartLine + consecEmptyCommentLines;
        //console.log(i, stackLine[0], userStartLine, consecEmptyCommentLines, estimatedLine);
        if ( estimatedLine == i + 1 ) break;
    }
    if ( estimatedLine >= sourceCode.split('\n').length ) {
        return result;
    }
    // setCursorInEditor(code.getEditor(), estimatedLine - 1, 0);
    var notDefMatch = regexNotDefined.exec(e.message);
    var notDef = notDefMatch ? notDefMatch[1] || notDefMatch[2] : undefined;
    //console.log(e, e.message, e.stack, notDefMatch, notDef, estimatedLine);
    result.error.line = estimatedLine - 1;
    result.error.problem = notDef;

    return result;
}

function get_browser_info() {
    var ua = navigator.userAgent, tem,
        M = ua.match(/(edge|opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if ( /trident/i.test(M[1]) ) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return { name: 'IE', version: (tem[1] || '' ) };
    }
    if ( M[1] === 'Chrome' ) {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/i);
        if ( tem != null ) return { name: tem[1] === 'OPR' ? 'Opera' : tem[1], version: tem[2] };
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ( (tem = ua.match(/version\/(\d+)/i)) != null ) M.splice(1, 1, tem[1]);
    return { name: M[0], version: M[1] };
}

// Source: http://stackoverflow.com/questions/17179175/display-web-console-on-screen

var consoleMethodNames = [
    "assert", "clear", "count",
    "debug", "dir", "dirxml", //"error", "exception", // We don't need error or exception
    "group", "groupCollapsed",
    "groupEnd", "info", "profile", "profileEnd",
    "table", "time", "timeEnd", "timeStamp",
    "trace", "warn"//, "log"
];

var methodColors = {
    'default': 'black', 'error': 'red', 'debug': 'blue', 'background': 'lightblue'
};

var consoleMethods = {}, jsEditor;


