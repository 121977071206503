/*global jQuery, mirror */
var modal = (function ($, mirror) {
    'use strict';

    var $modalContact,
        $modalContactTrigger,
        $pointsModal,
        $pointsModalTrigger,
        $modals,
        $autoModals,
        $quizModals,
        $quizModalAnswers,
        $instructionModals,
        $btnInstructionModalNext,
        $btnContactFormSubmit,
        $projectModal,
        $courseBadge,
        $hiddenPopovers,
        $profileEditTrigger,
        $profileEdit,
        $profileEditAlerts,
        $onboarding,
        $btnSubmitOnboarding,
        $btnSaveOnboarding,
        $btnSaveProfilePic,
        initialized = false;

    /**
     * Opens all Automatic Modals
     * Sets popovers for Course Badges
     */
    function init() {
        cacheDom();
        bindEvents();

        openModals();
        initCourseBadges();

        if ( $profileEdit.length && $profileEditAlerts.length ) {
            showProfileEdit();
        }

        if ($onboarding.length) {
            tour.run('onboarding');
        }

        initialized = true;
    }

    function cacheDom() {
        $modals = $('.modal');
        $quizModals = $('.js-modal-quiz');
        $quizModalAnswers = $('.answer');
        $instructionModals = $('.js-modal-instruction');
        $autoModals = $('.js-modal-show');

        $modalContact = $('.js-modal-contact');
        $modalContactTrigger = $('.js-btn-contact');
        $btnContactFormSubmit = $('.js-btn-form-submit');

        $pointsModal = $("#points-modal");
        $pointsModalTrigger = $("#btn-points");
        $btnInstructionModalNext = $('.js-btn-modal-instructions');
        $projectModal = $('#project-modal');

        $courseBadge = $('.js-course-badge');
        $hiddenPopovers = $('.hidden-popovers');

        $profileEditTrigger = $('.profile-edit-form-trigger');
        $profileEdit = $('.profile-edit-form');
        $profileEditAlerts = $profileEdit.find('.alert');

        $onboarding = $('.js-onboarding-unit');
        $btnSubmitOnboarding = $('.js-btn-submit-onboarding');
        $btnSaveOnboarding = $('.js-btn-save-onboarding');
        $btnSaveProfilePic = $('.js-btn-save-profile-pic');
    }

    function bindEvents() {

        $modalContactTrigger.on("click", (function (modal) {
            return function () {
                closeAll();
                modal.modal();
            };
        }($modalContact)));

        $pointsModalTrigger.on("click", (function (modal) {
            return function () {
                modal.modal();
            };
        }($pointsModal)));

        $modals.on('shown.bs.modal shown.bs.tab', function () {
            mirror.refresh();
        });

        $modals.on('hidden.bs.modal', function () {
            if ( allClosed() ) {
                //run first tour if all modals at task 1 are closed
                if ( progress.part === 1 && progress.courseId === 1 ) {
                    tour.run('first');
                }
            }
        });

        $modalContact.on('hidden.bs.modal', function () {
            if ( YPP.points !== undefined ) {
                feedback.showPointsScored(YPP.points);
            }
            $(this).find('.modal-contact-form').show();
            $(this).find('.modal-contact-form-success').hide();
            $(this).find('.js-form-error').hide();
        });

        $btnInstructionModalNext.on('click', function () {
            $(this).closest('.modal').modal('hide');
        });

        $("[data-hide]").on("click", function () {
            $(this).closest("." + $(this).attr("data-hide")).hide();
        });

        $btnContactFormSubmit.on("click", function (e) {
            e.preventDefault();
            $btnContactFormSubmit.attr('disabled', 'disabled');

            $.ajax({
                    type: "POST",
                    url: '/contact',
                    dataType: "json",
                    data: $(this).parents('form:first').serialize()
                })
                .done(function (data) {
                    $modalContact.find('.modal-contact-form').slideUp(1200, function () {
                        $modalContact.find('.modal-contact-form-success').slideDown(400);
                    });
                    if ( data.hasOwnProperty('points') ) {
                        YPP.points = data.points;
                    }
                })
                .fail(function (xhr) {
                    function capitalizeFirstLetter(string) {
                        return string.charAt(0).toUpperCase() + string.slice(1);
                    }

                    var errors = JSON.parse(xhr.responseText);
                    $.each(errors, function (index, error) {
                        $.each(error, function (index, message) {
                            var alert = $modalContact.find('.js-form-error');
                            alert.find('.js-form-error-text').html(capitalizeFirstLetter(message));
                            alert.show();
                        });
                    });
                })
                .always(function () {
                    $btnContactFormSubmit.removeAttr('disabled');
                });
        });

        $quizModalAnswers.on('click', handleQuizAnswer);

        $('body').on('click', function (e) {
            $courseBadge.each(function () {
                // hide any open popovers when the anywhere else in the body is clicked
                if ( !$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0 ) {
                    $(this).popover('hide');
                }
            });
        });

        $profileEditTrigger.on('click', function (event) {
            event.preventDefault();
            showProfileEdit();
        });

        $btnSubmitOnboarding.on('click', function () {
            tour.next();
        });

        $btnSaveOnboarding.on('click', function () {
            var data = {
                'username': $('#input-username').val(),
                'profile_code': code.getUserCode(),
                'profile_pic': canvas.getPreview()
            };
            $.ajax({
                    type: "POST",
                    url: '/flash',
                    dataType: "json",
                    data: $.param(data)
                })
                .done(function () {
                    return window.location.href = '/join';
                });
        });

        $btnSaveProfilePic.on('click', function () {
            var data = {
                'profile_code': code.getUserCode(),
                'profile_pic': canvas.getPreview()
            };
            $.ajax({
                    type: "POST",
                    url: '/users/updatePic',
                    dataType: "json",
                    data: $.param(data)
                })
                .done(function (data) {
                    return window.location.href = data.redirect;
                });
        });

    }

    /**
     * Closes all modals.
     */
    function closeAll() {
        if ( !initialized ) {
            return;
        }
        $modals.each(function () {
            $(this).modal('hide');
        });
    }

    /**
     * Returns true if at least one modal is open.
     * @returns {boolean}
     */
    function isOpen() {
        return !allClosed();
    }

    /**
     * Returns true if all modals are closed.
     * @returns {boolean}
     */
    function allClosed() {
        var closed = true;
        $modals.each(function () {
            if ( $(this).hasClass('in') ) {
                closed = false;
                return false;
            }
        });
        return closed;
    }

    function openModals() {
        $autoModals.each(function () {
            $(this).modal({
                backdrop: 'static',
                keyboard: false
            });
        });
    }

    function initCourseBadges() {
        $courseBadge.each(function () {
            if ( !$(this).find('.hidden-popover').length ) {
                return false;
            }

            var container = getPopoverContainer($(this));
            $(this).popover({
                'placement': getPopoverPlacement,
                'content': 'some content',
                'container': container,
                'viewport': {
                    'selector': '.full-screen',
                    'padding': '10'
                },
                'delay': {
                    "show": 200,
                    "hide": 0
                },
                'trigger': 'click',
                'template': $(this).find('.hidden-popover').html().toString()
            });

            $(this).on('shown.bs.popover', function () {
                var popoverId = $(this).attr('aria-describedby'),
                    popover = $('#' + popoverId),
                    left = parseFloat(popover.css('left'));

                left = Math.min(window.innerWidth - popover.outerWidth() - 10, left);
                left = Math.max(10, left);

                popover.css('left', left);
            });

        });
    }

    function getPopoverPlacement(popover, element) {
        var width = window.innerWidth,
            left = $(element).offset().left;

        if ( left < width / 2 ) {
            return 'right';
        }
        return 'left';
    }

    function getPopoverContainer(badge) {
        return (badge.hasClass('course-badge-index')) ? false : 'body';
    }

    function openPublishModal() {
        var wait = 0,
            image;

        if ( progress.courseId === 3 ) {
            canvas.getSketch().setup();
            wait = 500;
        }

        setTimeout(function () {
            image = canvas.getPreview();
            $projectModal.find('#project-preview img').attr('src', image);
            $projectModal.find('#project-form-img').attr('value', image);
        }, wait);

        $projectModal.find('#project-form-user-code').text(code.getLevelCode());

        $projectModal.modal('show');
    }

    function handleQuizAnswer() {

        var $answer = $(this),
            answer = $answer.data('answer'),
            $feedback = $answer.closest('.question-wrapper').find(".feedback[data-answer='" + answer + "']"),
            $quizModal = $answer.closest('.js-modal-quiz'),
            correctAnswer = $answer.closest('.question-wrapper').data('correct-answer'),
            quizId = $quizModal.data('quiz-id');

        if ( $answer.hasClass('fault') ) {
            return;
        }

        $('.feedback').hide();

        ajax.interact('attempted', 'quiz', quizId, answer);

        if ( answer !== correctAnswer ) {
            ajax.interact('failed', 'quiz', quizId);

            $answer.addClass('fault');

            $feedback.find('img').attr('src', "/assets/img/quiz-feedback-fault.png");
            $feedback.find('p').addClass('false');
            $feedback.show();
            return;
        }

        $feedback.find('img').attr('src', "/assets/img/quiz-feedback-correct.png");
        $feedback.find('p').addClass('correct');
        $feedback.show();

        ajax.interact('completed', 'quiz', quizId);

        setTimeout(function () {
            $('.feedback').hide();
            return $answer.closest('.modal').modal('hide');
        }, 1500);
    }

    function showProfileEdit() {
        $profileEdit.slideToggle({
            'duration': 500
        });
    }

    return {
        init: init,
        closeAll: closeAll,
        openPublishModal: openPublishModal,
        isOpen: isOpen
    };

})
(jQuery, mirror);



/*
 var Bookblock = (function () {

 var config = {},
 data = {
 'username': null,
 'profile_code': null,
 'profile_pic': null
 },
 init = function () {
 config.$bookBlock = $bookBlock;
 config.$navPrev = $('.js-bb-nav-prev');
 config.$navNext = $('.js-bb-nav-next');
 config.$navNextName = $('.js-bb-nav-next-name');
 config.$navNextPic = $('.js-bb-nav-next-pic');
 config.$bookBlock.bookblock({
 startPage: 1,
 orientation: 'vertical',
 direction: 'ltr',
 speed: 1000,
 easing: 'ease-in-out',
 shadows: true,
 shadowSides: 0.6,
 shadowFlip: 0.3,
 circular: false,
 onEndFlip: function (old, page, isLimit) {
 base.loop(); // to keep coderobo on page 1 moving
 switch ( page ) {
 case 0:
 break;
 case 1:
 $onboardingUsernameInput.focus();
 break;
 case 2:
 mirror.refresh();
 $onboardingPopover.popover('hide');
 $onboardingPopover.data('bs.popover', null).popover({
 'placement': 'top',
 'html': true,
 'title': lang.trans('onboarding-popover-title'),
 'content': "<div class='row'>" +
 "<div class='col-md-4 tour-pic'>" +
 "<img src='/assets/img/coderobo-results.gif' alt='coderobo gif'>" +
 "</div>" +
 "<div class='col-md-8 tour-text'>" +
 "<p>" + lang.trans('onboarding-popover-content') + "</p>" +
 "</div>" +
 "</div>",
 'template': '<div class="popover points-popover" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>'
 });
 $onboardingPopover.popover('show');
 $onboardingPopover.on('click', function () {
 $onboardingPopover.popover('hide');
 });
 break;
 case 3:
 $onboardingRegisterForm.find("input[name='email']").focus();
 break;
 default:
 //
 }
 return false;
 }
 });
 initEvents();
 },
 initEvents = function () {

 config.$navNext.on('click', function () {
 return next();
 });

 config.$navNextName.on('click', function () {
 return next(2);
 });

 config.$navNextPic.on('click', function () {
 return next(3);
 });

 config.$navPrev.on('click', function () {
 return config.$bookBlock.bookblock('prev');
 });

 $(document).keydown(function (e) {
 var keyCode = e.keyCode || e.which;
 if ( keyCode === 13 ) {
 if ( document.activeElement === $onboardingUsernameInput[0] ) {
 return next(2);
 }
 }
 });
 },
 next = function (page) {
 switch ( page ) {
 case 2:
 checkUsername(function () {
 data.username = $onboardingUsernameInput.val();
 $onboardingUsername.text(data.username.toUpperCase());
 config.$bookBlock.bookblock('next');
 });
 break;
 case 3:
 data.profile_code = code.getUserCode();
 data.profile_pic = canvas.getPreview();
 register();
 break;
 default:
 config.$bookBlock.bookblock('next');
 }
 },
 checkUsername = function (callback) {
 $.ajax({
 type: "POST",
 url: '/onboarding/username',
 dataType: "json",
 data: { 'username': $onboardingUsernameInput.val() }
 }).done(function (data) {
 if ( data.feedback !== null ) {
 $onboardingUsernameInput.blur();
 feedback.flash(data.feedback, lang.trans('feedback-title'), 'wrong');
 return false;
 }
 callback();
 });
 },
 register = function () {
 $.ajax({
 type: "POST",
 url: '/flash',
 dataType: "json",
 data: $.param(data)
 })
 .done(function (data) {
 window.location.replace('/join');
 });
 };

 return {
 init: init,
 next: next
 };

 })();

 */


