/*global YPP, jQuery, Chart, lang */
var admin = (function ($, Chart, lang) {
    "use strict";

    var $editChangePart,
        $analyticsTab,
        $analyticsSelect,
        $quizElements,
        $dashboard,
        charts = [];

    function init() {
        cacheDom();
        bindEvents();

        if (YPP.hasOwnProperty('conversion')) {
            convert(YPP.conversion);
        }
    }

    function cacheDom() {
        $editChangePart = $('.js-course-edit-part');
        $analyticsTab = $('.js-analytics-trigger');
        $analyticsSelect = $('.js-analytics-select');
        $quizElements = $("*[data-element='quiz']");
        $dashboard = $('.js-dashboard');
    }

    function bindEvents() {
        $editChangePart.on('change', function () {
            progress.goToPart(this.value);
        });
        $analyticsTab.on('shown.bs.tab', refreshTeacherDashboard);
        $analyticsSelect.on('change', function (e) {
            refreshTeacherDashboard($(this).val());
        });
    }

    function convert(plan) {

        var w = window;
        w.google_conversion_id = 963069458;
        if (plan === "free" ) {
            w.google_conversion_label = "BSaPCJ_Uom0QkoydywM"; // free plan
            w.google_conversion_value = 0;
        } else {
            w.google_conversion_label = "b6H_CMLGu20QkoydywM"; // paid plan
            w.google_conversion_value = 9;
        }
        w.google_conversion_currency = "EUR";
        w.google_remarketing_only = false;
        w.google_conversion_format = "3";

        var conv_handler = window['google_trackConversion'];
        if ( typeof(conv_handler) === 'function' ) {
            conv_handler();
        }
    }


    function refreshTeacherDashboard(courseId) {

        courseId = ( typeof courseId === 'string') ? courseId : 1;

        charts.forEach(function (chart) {
            chart.destroy();
        });

        $.getJSON('/teacher/report/tasks/' + courseId, { teacher_id: YPP.userId }, function (data) {
            generateIndividualTaskGraph(data);
            generateAggregateTaskGraph(data);
        });

        $.getJSON('/teacher/report/quizzes/' + courseId, { teacher_id: YPP.userId }, function (data) {

            if ( data.length === 0 ) {
                $quizElements.hide();
                return;
            }

            generateIndividualQuizGraph(data);
            generateAggregateQuizGraph(data);

            $quizElements.show();
        });

    }

    function generateIndividualTaskGraph(data) {
        var $canvas = $("#task-canvas");

        if ( !$canvas.length ) {
            return;
        }

        var canvas = $canvas.get(0).getContext('2d'),
            chartData = {
                labels: data.students.reverse(),
                datasets: [
                    {
                        label: lang.trans('dashboard-attempts'),
                        fillColor: "rgba(153,153,153,0.5)",
                        strokeColor: "rgba(153,153,153,0.5)",
                        data: data.tasks_attempted.reverse()
                    },
                    {
                        label: lang.trans('dashboard-completed'),
                        fillColor: "rgba(0,204,153,0.5)",
                        strokeColor: "rgba(0,204,153,0.5)",
                        data: data.tasks_completed.reverse()
                    },
                    {
                        label: lang.trans('dashboard-cheats'),
                        fillColor: "rgba(255,70,75,0.5)",
                        strokeColor: "rgba(255,70,75,0.5)",
                        data: data.tasks_cheated.reverse()
                    }
                ]
            };

        canvas.canvas.width = $canvas.width();
        canvas.canvas.height = data.students.length * 50;

        var chart = new Chart(canvas).HorizontalBar(chartData, {
            /*
             scaleOverride: true,
             scaleSteps: data.total_tasks,
             scaleStepWidth: 1,
             scaleStartValue: 0,
             */
            legendTemplate: "<ul class=\"<%=name.toLowerCase()%>-legend\"><% for (var i=0; i<datasets.length; i++){%><li><i class=\"fa fa-circle\"' style=\"color:<%=datasets[i].strokeColor%>\"></i> <%if(datasets[i].label){%><%=datasets[i].label%><%}%></li><%}%></ul>"
        });

        charts.push(chart);

        var $legend = $canvas.parent().find('.js-graph-legend');
        if ( $legend.length ) {
            $legend.html(chart.generateLegend());
        }
    }

    function generateAggregateTaskGraph(data, $canvas) {

        $canvas = (typeof $canvas !== 'undefined') ? $canvas : $("#task-aggregate-canvas");

        if ( !$canvas.length ) {
            return;
        }

        var canvas = $canvas.get(0).getContext('2d'),
            chartData = {
                labels: data.tasks,
                datasets: [
                    {
                        label: lang.trans('dashboard-attempts'),
                        fillColor: "rgba(153,153,153,0.2)",
                        strokeColor: "rgba(153,153,153,1)",
                        pointColor: "rgba(153,153,153,1)",
                        pointStrokeColor: "#fff",
                        pointHighlightFill: "#fff",
                        pointHighlightStroke: "rgba(220,220,220,1)",
                        data: data.attempted
                    },
                    {
                        label: lang.trans('dashboard-completed'),
                        fillColor: "rgba(0,204,153,0.2)",
                        strokeColor: "rgba(0,204,153,1)",
                        pointColor: "rgba(0,204,153,1)",
                        pointStrokeColor: "#fff",
                        pointHighlightFill: "#fff",
                        pointHighlightStroke: "rgba(151,187,205,1)",
                        data: data.completed
                    },
                    {
                        label: lang.trans('dashboard-cheats'),
                        fillColor: "rgba(255,70,75,0.5)",
                        strokeColor: "rgba(255,70,75,0.5)",
                        pointColor: "rgba(255,70,75,0.5)",
                        pointStrokeColor: "#fff",
                        pointHighlightFill: "#fff",
                        pointHighlightStroke: "rgba(220,220,220,1)",
                        data: data.cheated
                    }]
            };

        canvas.canvas.width = $canvas.width();
        canvas.canvas.height = $canvas.width() / 2;

        var chart = new Chart(canvas).Line(chartData, {
            legendTemplate: "<ul class=\"<%=name.toLowerCase()%>-legend\"><% for (var i=0; i<datasets.length; i++){%><li><i class=\"fa fa-circle\"' style=\"color:<%=datasets[i].strokeColor%>\"></i> <%if(datasets[i].label){%><%=datasets[i].label%><%}%></li><%}%></ul>"
        });

        charts.push(chart);

        var $legend = $canvas.parent().find('.js-graph-legend');
        if ( $legend.length ) {
            $legend.html(chart.generateLegend());
        }
    }

    function generateAggregateQuizGraph(data) {

        var $canvas = $("#quiz-aggregate-canvas");

        if ( !$canvas.length ) {
            return;
        }

        var canvas = $canvas.get(0).getContext('2d'),
            chartData = {
                labels: data.quizzes,
                datasets: [
                    {
                        label: lang.trans('dashboard-attempts'),
                        fillColor: "rgba(153,153,153,0.5)",
                        strokeColor: "rgba(153,153,153,0.5)",
                        data: data.attempted
                    },
                    {
                        label: lang.trans('dashboard-completed'),
                        fillColor: "rgba(0,204,153,0.5)",
                        strokeColor: "rgba(0,204,153,0.5)",
                        data: data.completed
                    }
                ]
            };

        canvas.canvas.width = $canvas.width();
        canvas.canvas.height = $canvas.width() / 2;

        var chart = new Chart(canvas).Bar(chartData, {
            legendTemplate: "<ul class=\"<%=name.toLowerCase()%>-legend\"><% for (var i=0; i<datasets.length; i++){%><li><i class=\"fa fa-circle\"' style=\"color:<%=datasets[i].strokeColor%>\"></i> <%if(datasets[i].label){%><%=datasets[i].label%><%}%></li><%}%></ul>"
        });

        charts.push(chart);

        var $legend = $canvas.parent().find('.js-graph-legend');
        if ( $legend.length ) {
            $legend.html(chart.generateLegend());
        }
    }

    function generateIndividualQuizGraph(data) {
        var $canvas = $("#quiz-canvas");

        if ( !$canvas.length ) {
            return;
        }

        var canvas = $canvas.get(0).getContext('2d'),
            chartData = {
                labels: data.students.reverse(),
                datasets: [
                    {
                        label: lang.trans('dashboard-attempts'),
                        fillColor: "rgba(153,153,153,0.5)",
                        strokeColor: "rgba(153,153,153,0.5)",
                        data: data.quizzes_attempted.reverse()
                    },
                    {
                        label: lang.trans('dashboard-completed'),
                        fillColor: "rgba(0,204,153,0.5)",
                        strokeColor: "rgba(0,204,153,0.5)",
                        data: data.quizzes_completed.reverse()
                    }
                ]
            };

        canvas.canvas.width = $canvas.width();
        canvas.canvas.height = data.students.length * 50;

        var chart = new Chart(canvas).HorizontalBar(chartData, {
            /*
             scaleOverride: true,
             scaleSteps: data.total_quizzes,
             scaleStepWidth: 1,
             scaleStartValue: 0,
             */
            legendTemplate: "<ul class=\"<%=name.toLowerCase()%>-legend\"><% for (var i=0; i<datasets.length; i++){%><li><i class=\"fa fa-circle\"' style=\"color:<%=datasets[i].strokeColor%>\"></i> <%if(datasets[i].label){%><%=datasets[i].label%><%}%></li><%}%></ul>"
        });

        charts.push(chart);

        var $legend = $canvas.parent().find('.js-graph-legend');
        if ( $legend.length ) {
            $legend.html(chart.generateLegend());
        }
    }

    return {
        init: init
    };

})(jQuery, Chart, lang);