/* This file contains the code module for interacting with the user code
 * It adheres to the revealing module pattern and only makes a few function available in public API
 ========================================================================================================= */

/*global jQuery, YPP, CodeMirror, feedback */
var code = (function ($, YPP) {
    "use strict";

    var startCode,
        editor = {},
        $commandField,
        $contentCode,
        $userCode,
        userCode,
        $resetBtn,
        $submitBtn,
        submitted = false,
        checked = false,
        solutions;

    function init() {

        cacheDom();

        editor = mirror.getEditor();

        // remember start code in case we need to reset
        startCode = $commandField.val();

        // parse solutions, if we have any
        if ( YPP.solution !== undefined ) {
            solutions = $.parseJSON(YPP.solution);
        }

        highlight();

        bindEvents();

        userCode = getUserCode();
    }

    function cacheDom() {
        $commandField = $('.js-command-field');
        $contentCode = $('.js-content-code');
        $userCode = $('#js-user-code');
        $resetBtn = $('#btn-reset');
        $submitBtn = $('#btn-submit');
    }

    function bindEvents() {

        if ( typeof editor !== 'undefined' ) {
            editor.on("change", function (cm, change) {
                respondToChange(change);
            });

            editor.on("focus", function () {
                feedback.hide();
            });
        }

        $resetBtn.on("click", function () {
            reset();
        });

        $submitBtn.on("click", function () {
            $submitBtn.prop('disabled', true);
            submit();
            $submitBtn.prop('disabled', false);
        });
    }

    /**
     * When code is submitted, there are three possible results: Correct, Wrong, and Invalid.
     * Each of the results have their own feedback to the user.
     */
    function submit() {
        submitted = true;
        checked = false;

        if ( !progress.isOpen() ) {
            ajax.interact('attempted', 'content', progress.contentId);
        }

        clean();

        execute();
    }

    function execute() {
        userCode = getUserCode();
        canvas.render();
    }

    function getUserCode() {
        return ( typeof editor === 'undefined' ) ? $userCode.html() : editor.getValue();
    }

    function getContentCode() {
        return $contentCode.html();
    }

    function getLevelCode() {
        if ( progress.courseId === 3 ) {
            var code = editor.getValue();
            code = code.replace(/(move\(.*\);|turn\(.*\);|turn\(.*\);)/g, '');
            code = code.replace(/(move\(.*\)|turn\(.*\)|turn\(.*\))/g, '');
            return code;
        }
        return getUserCode();
    }

    function setUserCode(code) {
        return editor.setValue(code);
    }

    function reset() {
        submitted = false;
        setUserCode(startCode);
        highlight();
        execute();
    }

    function highlight() {

        // conditionals for landing page and no highlights (otherwise parseJSON fails)
        if ( YPP.highlights === undefined || !YPP.highlights ) {
            return;
        }

        var highlights = $.parseJSON(YPP.highlights);

        $.each(highlights, function (key, value) {
            if ( value.hasOwnProperty('start') ) {
                var options = { 'className': 'line-highlight', 'clearWhenEmpty': true };
                editor.markText(value.start, value.end, options);
            }
            if ( value.hasOwnProperty('line') ) {
                editor.addLineClass(value.line, 'background', 'line-highlight');
            }
        });

    }

    function clean() {
        for ( var line = 0; line < editor.lineCount(); line++ ) {
            cleanLine(line);
        }
    }

    function cleanLine(line) {
        editor.removeLineClass(line, 'background', 'line-error');
        editor.removeLineClass(line, 'background', 'line-highlight');
    }

    function setLineError(line) {
        editor.addLineClass(line - 1, 'background', 'line-error');
    }

    /**
     * This function is called from the sketch in case it requires a code check.
     *
     * @returns {*}
     */
    function check() {

        var correct = false;

        // if code is not submitted by user, we need not check the code
        if ( !submitted || checked) {
            return;
        }

        // if code is submitted and there are no solutions, the answer is correct
        if ( !solutions) {
            return progress.complete();
        }

        checked = true;

        //ignore any whitespace
        var userCode = getUserCode().replace(/\s+/g, '');

        $.each(solutions, function (index, lines) {

            var correctLines = 0,
                requiredLines = 0,
                position = -1;

            $.each(lines, function (index, line) {

                requiredLines++;

                line = line.replace(/\s+/g, '');

                var mirrors = mirrorSolutions(line);

                for ( var i = 0; i < mirrors.length; i++ ) {
                    var givenLines = getIndicesOf(mirrors[i], userCode, true);
                    for ( var j = 0; j < givenLines.length; j++ ) {
                        if ( givenLines[j] > position ) {
                            position = givenLines[j];
                            correctLines++;
                            break;
                        }
                    }
                }
            });

            if ( correctLines >= requiredLines ) {
                correct = true;
                return false;
            }

        });

        if ( correct ) {
            return progress.complete();
        }

        feedback.fresh(true);

    }

    function respondToChange(change) {
        for ( var i = change.from.line; i <= change.to.line; i++ ) {
            cleanLine(change.from.line);
        }
    }

    /**
     * Returns an array of similar solutions
     * Currently for 'line' and 'triangle'
     */
    function mirrorSolutions(source) {
        function triangle(x, y, z) {
            return 'triangle(' + x + ',' + y + ',' + z + ')';
        }

        var regexLineObj = /line\((\d+,\d+),(\d+,\d+)\)/g,
            regexTriangleObj = /triangle\((\d+,\d+),(\d+,\d+),(\d+,\d+)\)/g,
            ls = regexLineObj.exec(source),
            ts = regexTriangleObj.exec(source);
        if ( ls !== null ) {
            return [ls[0], ls[0].replace(ls[2], ls[1]).replace(ls[1], ls[2])];
        } else if ( ts !== null ) {

            var trs = [];
            for ( var i = 1; i <= 3; i++ ) {
                trs.push(triangle(ts[i], ts[Math.max(1, (i + 1) % 4)], ts[(i + 1) % 3 + 1]));
                trs.push(triangle(ts[i], ts[(i + 1) % 3 + 1], ts[Math.max(1, (i + 1) % 4)]));
            }
            return trs;
        }
        return [source];
    }

    function getIndicesOf(searchStr, str, caseSensitive) {
        var startIndex = 0, searchStrLen = searchStr.length;
        var index, indices = [];
        if ( !caseSensitive ) {
            str = str.toLowerCase();
            searchStr = searchStr.toLowerCase();
        }
        while ( (index = str.indexOf(searchStr, startIndex)) > -1 ) {
            indices.push(index);
            startIndex = index + searchStrLen;
        }
        return indices;
    }

    return {
        init: init,
        setLineError: setLineError,
        check: check,
        submit: submit,
        reset: reset,
        isSubmitted: function () {
            return submitted;
        },
        getUserCode: function () {
            return (typeof userCode === 'undefined') ? getUserCode() : userCode;
        },
        getContentCode: getContentCode,
        getLevelCode: getLevelCode
    };

})(jQuery, YPP);

