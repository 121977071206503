/*global jQuery, YPP, lang, progress */
var instruction = (function ($, YPP, lang, progress) {
    "use strict";

    var $instructions,
        $hintBtn,
        $hintModal,
        $hintElements,
        $solutionElement,
        $buySolutionBtn,
        resized = false;

    function init() {
        cacheDom();
        bindEvents();

        if ( progress.part !== 0 ) {
            $buySolutionBtn.popover({
                trigger: 'hover',
                placement: 'top',
                html: true,
                content: lang.trans('hint-popover-content'),
                'template': '<div class="popover points-popover" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>',
                'title': lang.trans('hint-popover-title')
            });
        }

        bumpIt();
    }

    function cacheDom() {
        $instructions = $(".instructions-wrapper");
        $hintBtn = $("#btn-hint");
        $hintModal = $("#hint-modal");
        $hintElements = $hintModal.find('.modal-hint-element');
        $solutionElement = $hintModal.find('.js-solution');
        $buySolutionBtn = $solutionElement.find('#js-buy-solution');
    }

    function bindEvents() {

        $hintBtn.on("click", function () {
            $hintModal.modal('show');
        });

        $buySolutionBtn.on('click', function () {
            if ( $(this).parent().hasClass('disabled') ) {
                return false;
            }
            ajax.interact('cheated', 'content', YPP.contentId);
        });

        $(window).resize(function () {
            resized = true;

            setInterval(function () {
                if ( resized ) {
                    resized = false;
                    bumpIt();
                }
            }, 250);
        });

        // Allow to go back and forth to solution tab
        $hintElements.on('click', function () {
            if ( !$(this).hasClass('js-solution') ) {
                $solutionElement.removeClass('active');
            }
        });

    }

    // Sticks the Action to the Bottom of the instructions
    // see http://blog.mojotech.com/responsive-dynamic-height-sticky-footers/http://blog.mojotech.com/responsive-dynamic-height-sticky-footers/
    function bumpIt() {
        var instruction = $('.js-instructions');
        instruction.css('padding-bottom', $('.action').outerHeight() + 30 + 50); // 50 is height of .instructions-btn
    }

    function scrollDown() {
        $instructions.scrollTop($instructions[0].scrollHeight);
    }

    function scrollUp() {
        $instructions.scrollTop(0);
    }

    return {
        init: init,
        scrollDown: scrollDown,
        scrollUp: scrollUp
    };

})(jQuery, YPP, lang, progress);