// TODO: MAKE MODULAR
// - separation of concerns
// - don't repeat yourself (DRY)
// - efficient DOM usage
// - no memory leaks
// - mustache JS for html templating
// - insert <script> only
// - pubsub https://www.youtube.com/watch?v=nQRXi1SVOow&list=PLoYCgNOIyGABs-wDaaxChu82q_xQgUb4f&index=4
// - styleguide https://github.com/rwaldron/idiomatic.js

/*global jQuery, admin, ajax, canvas, error, code, feedback, instruction, mirror, modal, progress */
var base = (function ($, admin, ajax, canvas, error, code, feedback, instruction, mirror, modal, progress) {
    'use strict';

    var $coursePage,
        $smallScreen,
        $bigScreen,
        $smallScreenTest,
        $mobileFriendly,
        initialized = false,
        $btnSignupSubmit;

    function init() {

        cacheDom();
        bindEvents();

        if ( screenTooSmall() && !$mobileFriendly ) {
            $bigScreen.css('display', 'none');
            $smallScreen.css('display', 'block');
            return;
        }

        $bigScreen.css('display', 'block');
        $smallScreen.css('display', 'none');

        mirror.init();
        error.init();
        modal.init();
        ajax.init();
        code.init();
        feedback.init();
        canvas.init();
        progress.init();
        instruction.init();
        admin.init();

        loopTransitions();

        initialized = true;
    }

    function cacheDom() {
        $smallScreen = $('#js-small-screen');
        $bigScreen = $('#js-big-screen');
        $smallScreenTest = $('#js-small-screen-test');
        $coursePage = $('#course-page');
        $btnSignupSubmit = $('#btn-signup-submit');
        $mobileFriendly = ($('meta[name="mobile-friendly"]').length > 0);
    }

    function bindEvents() {
        /**
         * Window Resize Event
         */
        var resized;
        $(window).resize(function () {
            resized = true;
        });
        setInterval(function () {
            if ( resized ) {
                resized = false;
                handleResize();
            }
        }, 250);

        /**
         * Disable Backspace Navigation
         */
        $(document).on('keydown', function (e) {
            var $target = $(e.target || e.srcElement);
            if ( e.keyCode === 8 && !$target.is('input,[contenteditable="true"],textarea') ) {
                e.preventDefault();
            }
        });

    }


    function handleResize() {
        if ( screenTooSmall() ) {
            modal.closeAll();
        }
        init();
    }

    function screenTooSmall() {
        return ($smallScreenTest.css('display') === 'block');
    }

    function loopTransitions() {
        var $transitionElements = $('.js-transition-loop'),
            loopTransition = function ($elem) {
                $elem.addClass('state-2');
                $elem.on('transitionend webkitTransitionEnd oTransitionEnd', function () {
                    if ( $elem.hasClass('state-1') ) {
                        $elem.removeClass('state-1').addClass('state-2');
                    } else {
                        $elem.removeClass('state-2').addClass('state-1');
                    }
                });
            };

        $transitionElements.each(function () {
            $(this).removeClass('state-1');
            $(this).removeClass('state-2');
            if ( $(this).is(":visible") ) {
                var $elem = $(this);
                loopTransition($elem);
            }
        });

    }

    return {
        init: init,
        loop: loopTransitions
    };

})(jQuery, admin, ajax, canvas, error, code, feedback, instruction, mirror, modal, progress);

/**
 * This is the main (and only) entry point at DOM ready
 */
if ( detectIE() ) {
    setTimeout(function () {
        base.init();
    }, 100);
} else {
    jQuery(document).ready(base.init);
}

/**
 * Detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */
function detectIE() {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if ( msie > 0 ) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if ( trident > 0 ) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if ( edge > 0 ) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
}