/*global jQuery, Bugsnag */
var error = (function ($, Bugsnag) {
    "use strict";

    var release;

    function init() {
        cacheDom();
        bindEvents();

        Bugsnag.apiKey = "a7e3d25fe7887df3fe27ecce1b2b7561";
        Bugsnag.notifyReleaseStages = ["local", "staging", "production"];

        release = $('meta[name=release]').attr('content');
        Bugsnag.releaseStage = release;
    }

    function cacheDom() {

    }

    function bindEvents() {

        window.onerror = function (message, source, lineno, colno, error) {
            //console.log('global error..', message, source, lineno, colno, error);
            notify(error);
            if ( error.canvas === true ) {
                // Prevent browser error messages.
                return true;
            }
        };
    }

    function throwError(error) {
        throw new CustomError(error);
    }

    function CustomError(error) {
        for (var property in error) {
            if (error.hasOwnProperty(property)) {
                this[property] = error[property];
            }
        }
        // this prevents browser error messages, and directs the trace analysis
        this.custom = true;
        // for all exceptions that occur while executing the sketch
        this.canvas = true;
        // extending CustomError from normal Error and get stack trace
        this.stack = new Error().stack;
        this.toString = function () {
            return this.name + ": " + this.message + '. User message: ' + this.feedback;
        };
    }

    function notify(error) {

        // attach user data
        if (YPP.userId !== undefined) {
            Bugsnag.user = {
                id: YPP.userId,
                username: YPP.username
            };
        }

        Bugsnag.notifyException(error);
    }

    return {
        init: init,
        throw: throwError
    };

})(jQuery, Bugsnag);
