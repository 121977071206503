/*global jQuery, lang */
var validate = (function ($, lang) {
    "use strict";

    function init() {
        cacheDom();
        bindEvents();
    }

    function cacheDom() {

    }

    function bindEvents() {

    }

    function decorate(method, name) {
        return function () {
            check(arguments, method, name);
            return method.apply(this, arguments);
        };
    }

    function check(parameters, method, name, option) {

        if ( !code.isSubmitted() ) {
            return;
        }

        var command = validationLookup[name];
        if (option !== undefined) {
            command = command[option];
        }

        if ( command.length ) {
            for ( var alternative = 0; alternative < command.length; alternative++ ) {
                var validated = true;
                try {
                    check(parameters, method, name, alternative);
                } catch ( error ) {
                    // select options errors are pushed through
                    if ( command.length <= alternative + 1 || error.push ) {
                        throw error;
                    }
                    validated = false;
                }
                if ( validated ) {
                    break;
                }
            }
            return;
        }

        // if only an example for the command is provided
        var example = ( typeof command.info === "string" ) ? command.info : command.info.example;
        command.info = {
            example: name + example.substr(example.indexOf('('), example.indexOf(')'))
        };

        // deduce the command and amount of parameters from the example and the number of rules
        $.extend(command.info, {
            command: name, //command.info.example.substr(0, command.info.example.indexOf('(')),
            amount: command.rules.length
        });


        for ( var i = 0; i < command.rules.length; i++ ) {
            validate(parameters[i], command.rules[i], i, command.info);
        }

        if ( parameters.length > command.rules.length ) {
            error.throw({
                name: "ArgumentError",
                feedback: lang.trans("error-parameter-amount", {
                    example: command.info.example,
                    command: command.info.command,
                    amount: command.info.amount
                })
            });
        }

        // for items that must directed horizontally or vertically (e.g. wall)
        if ( command.hasOwnProperty('special') ) {
            if ( !(parameters[0] === parameters[2] || parameters[1] === parameters[3]) ) {
                error.throw({
                    name: "ArgumentError",
                    feedback: lang.trans("error-orientation", {
                        example: command.info.example,
                        item: command.info.command
                    })
                });
            }
        }
    }

    function validate(parameter, rule, key, info) {

        // The default object if only a string for the type is given.
        if ( typeof rule === "string" ) {
            rule = {
                type: rule
            };
        }

        rule.type = (typeof rule.type === 'string') ? [rule.type] : rule.type;

        if ( typeof parameter === 'undefined' ) {
            if ( rule.hasOwnProperty('optional') ) {
                return;
            } else {
                error.throw({
                    name: "ArgumentError",
                    feedback: lang.trans("error-parameter-amount", {
                        example: info.example,
                        command: info.command,
                        amount: info.amount
                    })
                });
            }
        }

        if ( $.inArray("integer", rule.type) !== -1 ) {
            if ( parameter !== parseInt(parameter, 10) ) {
                error.throw({
                    name: "ArgumentError",
                    feedback: lang.trans("error-parameter-type", {
                        example: info.example,
                        command: info.command,
                        type: lang.trans("error-type-integer"),
                        parameter: key + 1
                    })
                });
            }
            if ( rule.hasOwnProperty("range") ) {
                if ( parameter < rule.range[0] || parameter > rule.range[1] ) {
                    error.throw({
                        name: "ArgumentError",
                        feedback: lang.trans("error-parameter-range", {
                            example: info.example,
                            command: info.command,
                            type: lang.trans("error-type-integer"),
                            low: rule.range[0],
                            high: rule.range[1],
                            parameter: key + 1
                        })
                    });
                }
            }
        }

        if ( $.inArray("string", rule.type) !== -1 ) {
            if ( typeof parameter !== "string" ) {
                error.throw({
                    name: "ArgumentError",
                    feedback: lang.trans("error-parameter-type", {
                        example: info.example,
                        command: info.command,
                        type: lang.trans("error-type-string"),
                        parameter: key + 1
                    })
                });
            }
        }

        if ( $.inArray("object", rule.type) !== -1 ) {
            if ( typeof parameter !== "object" ) {
                error.throw({
                    name: "ArgumentError",
                    feedback: lang.trans("error-parameter-type", {
                        example: info.example,
                        command: info.command,
                        type: lang.trans("error-type-object"),
                        parameter: key + 1
                    })
                });
            }
        }

        if ( $.inArray("select", rule.type) !== -1 ) {

            //TODO: Fix select with Objects (e.g. Images for SetBackground());
            if ( typeof parameter === 'object' ) {
                return;
            }

            if ( $.inArray(parameter, rule.options) === -1 ) {
                var optionString = '';
                $.each(rule.options, function (index, value) {
                    optionString += value;
                    if ( index < rule.options.length - 1 ) {
                        optionString += ', ';
                    }
                });
                error.throw({
                    name: "ArgumentError",
                    feedback: lang.trans("error-select", {
                        options: optionString,
                        example: info.example,
                        command: info.command,
                        parameter: key + 1
                    }),
                    push: true
                });
            }
        }
    }

    var robot = {
        rules: [
            {
                type: ["string", "select"],
                options: ["charlie", "rosie", "cody", "rob", "unknown"]
            }
        ],
        info: "robot('cody');"
    };

    var point = {
        rules: ["integer", "integer"],
        info: "point(100,200);"
    };

    var line = {
        rules: ["integer", "integer", "integer", "integer"],
        info: "line(100,100,150,200);"
    };

    var triangle = {
        rules: ["integer", "integer", "integer", "integer", "integer", "integer"],
        info: "triangle(100,100,150,200,100,300);"
    };

    var textFont = {
        rules: [
            {
                type: "select",
                options: ["Jellybean", "Bubblegum", "Cookie", "Lollipop"]
            },
            "integer"
        ],
        info: "textFont(Jellybean,22);"
    };

    var words = {
        rules: [
            "string",
            "integer",
            "integer"
        ],
        info: "words(\"Hello\",200,300);"
    };

    var fill = [{
        rules: [
            {
                type: ["string", "select"],
                options: ["red", "green", "blue", "black", "white"]
            }
        ],
        info: "fill('red');"
    }, {
        rules: [
            {
                type: "integer",
                range: [0, 255]
            },
            {
                type: "integer",
                range: [0, 255]
            },
            {
                type: "integer",
                range: [0, 255]
            }
        ],
        info: "fill(255,100,147);"
    }];

    var noFill = {
        rules: [],
        info: "noFill();"
    };

    var setBackgroundImage = {
        rules: [
            {
                type: ["object", "select"],
                options: ["space", "spaceship"]
            }],
        info: "setBackgroundImage(space);"
    };

    var image = {
        rules: [
            {
                type: ["select"],
                options: ["happyGnofu", "happyGnom", "happyGomox", "evilGnofu", "evilGnom", "evilGomox", "ufo", "space", "spaceship"]
            },
            "integer",
            "integer",
            "integer",
            "integer"
        ],
        info: "image(happyGnofu,100,100,50,200);"
    };

    var move = {
        rules: ["integer"],
        info: "move(250);"
    };

    var turn = {
        rules: [{
            type: ["integer", "select"],
            options: [0, 90, 180, 270, 360]
        }],
        info: "turn(90);"
    };

    var shuttle = {
        rules: ["integer", "integer", {
            type: "integer",
            optional: true
        }],
        info: "shuttle(200,200,500);"
    };

    var planet = {
        rules: ["integer", "integer", {
            type: ["string", "select"],
            options: ["mercury", "venus", "earth", "mars", "jupiter", "uranus", "neptune"],
            optional: true
        }],
        info: "planet(200,300,\"earth\");"
    };

    var star = {
        rules: ["integer", "integer", {
            type: ["string", "select"],
            options: ["red", "purple", "pink", "green"],
            optional: true
        }],
        info: "star(200,300,\"red\");"
    };

    var wall = {
        rules: ["integer", "integer", "integer", "integer"],
        special: "orientation",
        info: "wall(200,200,400,200);"
    };

    var alien = {
        rules: ["integer", "integer", "integer", "integer"],
        special: "orientation",
        info: "alien(200,200,400,200);"
    };

    var validationLookup = {
        point: point,
        line: line,
        rect: line,
        ellipse: line,
        triangle: triangle,
        textFont: textFont,
        words: words,
        fill: fill,
        stroke: fill,
        background: fill,
        noFill: noFill,
        noStroke: noFill,
        robot: robot,
        setBackgroundImage: setBackgroundImage,
        image: image,
        move: move,
        turn: turn,
        shuttle: shuttle,
        diver: shuttle,
        mothership: point,
        submarine: point,
        planet: planet,
        star: star,
        asteroid: point,
        urchin: point,
        wall: wall,
        alien: alien,
        octopus: alien,
        fuel: shuttle,
        oxygen: shuttle
    };

    return {
        init: init,
        check: check,
        decorate: decorate
    };

})
(jQuery, lang);