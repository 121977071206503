/*global jQuery, YPP */
var progress = (function ($, YPP) {
    "use strict";

    var part = parseInt(YPP.part, 10),
        parts = parseInt(YPP.parts, 10),
        courseId = parseInt(YPP.courseId, 10),
        contentId = parseInt(YPP.contentId, 10),
        openId = parseInt(YPP.openId, 10),
        $nextBtn,
        $nextOnboardingBtn,
        $previousBtn,
        $finishBtn,
        $publishBtn,
        $projectPage;

    function init() {
        cacheDom();
        bindEvents();

        /* Hide finish-button until last exercise is reached */
        if ( isLast() || isOpen() ) {
            $nextBtn.hide();
            $finishBtn.show();
        }

        /* Disable previous button when we're at part 1 */
        if ( part === 1 || isOpen() ) {
            $previousBtn.prop("disabled", true);
        }

        /* Disable next- and publish-button until code is correct */
        $nextBtn.prop("disabled", true);
        $finishBtn.prop("disabled", true);

        if ( part === 1 && courseId === 1 ) {
            // this tour is kickstarted from modal.js
            // tour.run('first');
        }

        if ( part === 6 && courseId === 1 ) {
            tour.run('second');
        }

    }

    function cacheDom() {
        $nextBtn = $('#btn-next');
        $previousBtn = $('#btn-previous');
        $finishBtn = $('#btn-finish');
        $publishBtn = $('#btn-publish');
        $nextOnboardingBtn = $('#btn-next-onboarding');
        $projectPage = $('.project-page');
    }

    function bindEvents() {
        $nextBtn.on("click", next);
        $previousBtn.on("click", previous);
        $finishBtn.on('mouseenter', function () {
            $(this).hide();
            $publishBtn.show();
        });
        $publishBtn.on('mouseleave', function () {
            $(this).hide();
            $finishBtn.show();
        });
        $publishBtn.on("click", function () {
            // TODO: check if valid code
            modal.openPublishModal();
        });
        $nextOnboardingBtn.on("click", nextOnboarding);
    }

    function complete() {

        enableNext();

        if ( !isOpen() ) {
            if ( isLast() ) {
                ajax.interact('completed', 'course', courseId);
            }
            ajax.interact('completed', 'content', contentId);
        }

        if ( tour.isRunning() ) {
            return tour.next();
        }
    }

    function enableNext() {
        if ( $nextBtn !== 'undefined' ) {
            $nextBtn.prop("disabled", false);
        }
        if ( $nextBtn !== 'undefined' ) {
            $finishBtn.prop("disabled", false);
        }
    }

    function next() {
        goToPart(part + 1);
    }

    function previous() {
        goToPart(part - 1);
    }

    function goToPart(part) {
        var url = window.location.href,
            urlArray = url.split('/');

        urlArray[urlArray.length - 1] = part;
        window.location.href = urlArray.join('/');
    }

    function isLast() {
        return (part === parts);
    }

    function isOpen() {
        return isNaN(part);
    }

    function isProject() {
        return ($projectPage.length > 0);
    }

    function nextOnboarding() {
        ajax.post('/users/updatePic', {
            'user_id': YPP.user_id,
            'code': code.getUserCode(),
            'img': canvas.getPreview()
        });
    }

    return {
        init: init,
        next: next,
        previous: previous,
        goToPart: goToPart,
        isOpen: isOpen,
        isProject: isProject,
        part: part,
        parts: parts,
        courseId: courseId,
        openId: openId,
        contentId: contentId,
        isLast: isLast,
        enableNext: enableNext,
        complete: complete
    };

})(jQuery, YPP);