/*global jQuery, feedback */
var ajax = (function ($, feedback) {
    'use strict';

    var token;

    /**
     * Sets up the CSRF token for Ajax Requests
     */
    function init() {
        cacheDom();
        bindEvents();

        $.ajaxSetup({
            headers: { 'X-CSRF-Token': token }
        });
    }

    function cacheDom() {
        token = $('meta[name=_token]').attr('content');
    }

    function bindEvents() {
        //
    }

    function interact(interaction, subject, id, info) {
        id = typeof id !== 'undefined' ? id : null;
        subject = typeof subject !== 'undefined' ? subject : null;
        info = typeof subject !== 'undefined' ? info : null;

        $.ajax({
            type: "GET",
            url: "/ajax/interaction",
            dataType: "json",
            data: {
                interaction: interaction,
                subject: subject,
                id: id,
                info: info
            }
        }).done(function (data) {
                if ( data.points !== null ) {
                    feedback.showPointsScored(data);
                }
            }
        );
    }

    function post(uri, data, callback) {
        return $.ajax({
            type: "POST",
            url: uri,
            dataType: "json",
            data: data
        }).done(function (data) {
                if ( typeof callback === 'function' ) {
                    callback(data);
                }
                if ( typeof data.redirect !== 'undefined' ) {
                    window.location.href = data.redirect;
                }
            }
        );
    }

    return {
        init: init,
        interact: interact,
        post: post
    };

})(jQuery, feedback);




