/*global jQuery */
var sketch = (function ($) {
    "use strict";

    var $canvasWrapper = $('#canvas'),
        $canvas = $("#processing-canvas"),
        context = ($canvas.length) ? $canvas[0].getContext("2d") : undefined;

    var p,
        Jellybean = "Jellybean",
        Lollipop = "Lollipop",
        Cookie = "Cookie",
        Bubblegum = "Bubblegum";

    function init() {
        cacheDom();
        bindEvents();
    }

    function cacheDom() {

    }

    function bindEvents() {

    }

    function getCode() {
        switch ( progress.courseId ) {
            case 1:
                return cardSketch;
            case 2:
                return quizSketch;
            case 3:
                return spaceSketch;
            case 4:
                return testSketch;
            case 5:
                return cardSketch;
            // for onboarding and profile pic edit
            default:
                return profileSketch;
        }

    }

    function handleError(error) {

        // non-validation errors
        if ( !error.custom ) {
            error = {
                name: error.name,
                message: error.message,
                stack: error.stack,
                custom: false,
                canvas: true,
                toString: function () {
                    return this.name + ": " + this.message + 'Stack Trace: ' + this.stack;
                }
            };
        }

        if ( code.isSubmitted() !== true ) {
            // this should return early if default code could contain non-custom errors
            return;
        }

        // Stop executing the sketch
        p.noLoop();

        // send feedback to User for the error that occured
        feedback.fresh(false, error);
    }

    function cardSketch(processing) {

        p = processing;

        p.setup = function () {
            p.size($canvasWrapper.width(), $canvasWrapper.height());
            p.scale(1, -1);
            p.translate(0, -p.height);
        };

        p.draw = function () {
            p.scale(1, -1);
            p.translate(0, -p.height);
            drawCoordinateSystem();
            p.fill(255, 255, 255);
            p.stroke(0, 204, 153);
            p.strokeWeight(4);

            try {
                eval(code.getUserCode());
            } catch ( error ) {
                return handleError(error);
            }

            code.check();
        };
    }

    function profileSketch(processing) {

        p = processing;

        var robotBlank,
            robotJake,
            robotJenny,
            robotLight,
            robotDarth;

        p.setup = function () {
            robotBlank = p.loadImage('/assets/img/robotblank.png');
            robotJake = p.loadImage('/assets/img/robot1.png');
            robotJenny = p.loadImage('/assets/img/robot2.png');
            robotLight = p.loadImage('/assets/img/robot4.png');
            robotDarth = p.loadImage('/assets/img/robot5.png');

            p.size($canvasWrapper.width(), $canvasWrapper.height());
            p.scale(1, -1);
            p.translate(0, -p.height);
        };

        p.draw = function () {
            p.scale(1, -1);
            p.translate(0, -p.height);
            p.strokeWeight(4);

            try {
                eval(code.getUserCode());
            } catch ( error ) {
                return handleError(error);
            }
        };

        function robot(image) {

            var img;

            p.scale(1, -1);
            p.translate(0, -p.height);
            p.imageMode(p.CENTER);

            var ratio = {
                'unknown': 254 / 435,
                'charlie': 300 / 336,
                'rosie': 287 / 435,
                'cody': 300 / 402,
                'rob': 250 / 435
            };

            var height = p.height * 5 / 6;

            switch ( image ) {
                case 'unknown':
                    img = robotBlank;
                    p.image(img, p.width / 2, 5 * p.height / 6, ratio[image] * 1.5 * p.height, 1.5 * p.height);
                    break;
                case 'charlie':
                    img = robotJake;
                    p.image(img, p.width / 2, p.height / 2, ratio[image] * height, height);
                    break;
                case 'rosie':
                    img = robotJenny;
                    p.image(img, p.width / 2, p.height / 2, ratio[image] * height, height);
                    break;
                case 'cody':
                    img = robotLight;
                    p.image(img, p.width / 2, p.height / 2, ratio[image] * height, height);
                    break;
                case 'rob':
                    img = robotDarth;
                    p.image(img, p.width / 2, p.height / 2, ratio[image] * height, height);
                    break;
                default:
                //
            }

            p.scale(1, -1);
            p.translate(0, -p.height);
        }

        robot = validate.decorate(robot, "robot");
    }

    function quizSketch(processing) {

        p = processing;

        var answer,
            a = 0,
            b = 1000,
            imageTitle = "imageTitle",
            backgroundImage,
            space = "space",
            spaceship = "spaceship",
            ufo = "ufo",
            button = "button",
            happyGnofu = "happyGnofu",
            happyGnom = "happyGnom",
            happyGomox = "happyGomox",
            evilGnofu = "evilGnofu",
            evilGnom = "evilGnom",
            evilGomox = "evilGomox",
        //for school quiz
            professor = "professor",
            science = "science",
            tick = "tick",
            thumbUp = "thumbUp",
            cross = "cross",
            thumbDown = "thumbDown";

        p.setup = function () {
            button = p.loadImage('/assets/img/instructions/button-quiz.png');
            space = p.loadImage('/assets/img/instructions/space.png');
            spaceship = p.loadImage('/assets/img/instructions/spaceship.png');
            ufo = p.loadImage('/assets/img/instructions/ufo.png');
            happyGnofu = p.loadImage('/assets/img/instructions/happy-gnofu.png');
            happyGnom = p.loadImage('/assets/img/instructions/happy-gnom.png');
            happyGomox = p.loadImage('/assets/img/instructions/happy-gomox.png');
            evilGnofu = p.loadImage('/assets/img/instructions/evil-gnofu.png');
            evilGnom = p.loadImage('/assets/img/instructions/evil-gnom.png');
            evilGomox = p.loadImage('/assets/img/instructions/evil-gomox.png');
            //for school quiz
            professor = p.loadImage('/assets/img/instructions/professor-up.png');
            science = p.loadImage('/assets/img/instructions/science-up.png');
            tick = p.loadImage('/assets/img/instructions/tick-up.png');
            cross = p.loadImage('/assets/img/instructions/cross-up.png');
            thumbUp = p.loadImage('/assets/img/instructions/correct-up.png');
            thumbDown = p.loadImage('/assets/img/instructions/wrong-up.png');

            p.size($canvasWrapper.width(), $canvasWrapper.height());
            p.smooth();

            answer = -1;
        };

        p.draw = function () {

            p.scale(1, -1);
            p.translate(0, -p.height);

            if ( progress.part < 6 || (progress.part > 9 && progress.part < 13) || progress.part > 17 || isNaN(progress.part) ) {
                p.background(199);
                drawCoordinateSystem("dark");
                if ( backgroundImage !== undefined ) {
                    p.image(backgroundImage, 0, 0, p.width, p.height);
                }
                p.stroke(240);
                p.fill(255);
                textFont(Jellybean, 26);
                p.image(button, p.round(p.width / 9), 325, p.round(7 * p.width / 9), 50);
                words("1", p.round(p.width / 9 + 0.45 * p.width / 9), 340);
                p.image(button, p.round(p.width / 9), 245, p.round(7 * p.width / 9), 50);
                words("2", p.round(p.width / 9 + 0.45 * p.width / 9), 260);
            } else {
                drawCoordinateSystem();
                p.stroke(0, 204, 153);
                p.strokeWeight(4);
                if ( backgroundImage !== undefined ) {
                    p.image(backgroundImage, 0, 0, p.width, p.height);
                }
            }

            p.fill(255);

            try {
                eval(code.getUserCode());
            } catch ( error ) {
                return handleError(error);
            }

            code.check();

        };

        p.mousePressed = function () {
            answer = -1;

            if ( p.mouseX > p.width / 9 &&
                p.mouseX < 8 * p.width / 9 &&
                p.height - p.mouseY > 325 &&
                p.height - p.mouseY < 325 + 50 ) {
                answer = 1;
            }

            if ( p.mouseX > p.width / 9 &&
                p.mouseX < 8 * p.width / 9 &&
                p.height - p.mouseY > 245 &&
                p.height - p.mouseY < 245 + 50 ) {
                answer = 2;
            }
        };

        function setBackgroundImage(image) {
            backgroundImage = image;
        }

        setBackgroundImage = validate.decorate(setBackgroundImage, "setBackgroundImage");

        function image(image, x, y, width, height) {
            p.image(image, x, y, width, height);
        }

        image = validate.decorate(image, "image");
    }

    function spaceSketch(processing) {
        p = processing;

        var level;

        p.setup = function () {
            p.size($canvasWrapper.width(), $canvasWrapper.height());
            p.imageMode(p.CENTER);
            if ( progress.openId === 11 ) {
                level = new WaterLevel();
            } else {
                level = new SpaceLevel();
            }
            level.setup();
            p.frameRate(100);
        };

        p.draw = function () {
            level.draw();
        };

        Function.prototype.extend = function (parent) {
            var Child = this;
            Child.prototype = parent;
            Child.prototype.$parent = parent;
            Child.prototype = new Child(Array.prototype.slice.call(1, arguments));
            Child.prototype.constructor = Child;
        };

        var Level = {
            setup: function () {
                p.frameRate(60);
                this.interactors = [];
                this.hero = new Hero();
                this.hero.init(50, 50);
                this.paused = false;
                this.completed = false;
                this.failed = false;
                this.planetsToVisit = 0;
                this.animationFrame = 0;
                this.scoreSent = false;
                this.background = p.loadImage(this.images.background);
                this.fuelTankImg = p.loadImage(this.images.fuelTankImg);
                this.completeAnimation = new Animation(this.images.completeAnimation, 6);
                this.failAnimation = new Animation(this.images.failAnimation, 8);
                this.animationFrames = 30;
                eval(code.getContentCode());
                try {
                    eval(code.getUserCode());
                } catch ( error ) {
                    return handleError(error);
                }
                this.complete = function () {
                    if ( !this.completed ) {
                        progress.complete();
                        this.completed = true;
                    }
                };
                this.fail = function () {
                    this.failed = true;
                };
                this.showMissionComplete = function () {
                    p.background(0, 204, 153);
                    context.font = "50px Cabin Sketch Bold";
                    p.fill(255);
                    words(lang.trans('canvas-mission-complete'), 25, p.height - 100);
                    this.completeAnimation.display(p.width / 2, p.height / 2 - 50, 200, 250);
                    context.font = "30px Architects Daughter";
                    words(lang.trans(this.config.fuelLeftMessage, { 'fuel': p.max(0, this.hero.fuel) }), 25, p.height - 140);

                    if ( progress.isProject() ) {
                        if ( !this.scoreSent ) {
                            canvas.highscore(p.max(0, this.hero.fuel));
                        }
                        this.scoreSent = true;
                    } else if ( !progress.isLast() && !progress.isOpen() ) {
                        p.fill(255);
                        p.rect(p.width / 2 - 150, 50, 300, 50, 7);
                        context.font = "30px Architects Daughter";
                        p.fill(0, 204, 153);
                        words(lang.trans('canvas-next-mission'), p.width / 2 - 130, 65);
                    }
                };
                this.showGameOver = function () {
                    p.background(176, 45, 68);
                    context.font = "80px Cabin Sketch Bold";
                    p.fill(255);
                    words(lang.trans('canvas-game-over'), 25, p.height - 100);
                    this.failAnimation.display(p.width / 2, p.height / 2 - 50, 200, 250);
                    p.fill(255);
                    p.rect(p.width / 2 - 150, 50, 300, 50, 7);
                    context.font = "40px Architects Daughter";
                    p.fill(176, 45, 68);
                    words(lang.trans('canvas-try-again'), p.width / 2 - 100, 60);
                };
            },
            draw: function () {
                p.scale(1, -1);
                p.translate(0, -p.height);
                p.image(this.background, p.width / 2, p.height / 2, p.width, p.height);
                if ( this.completed ) {
                    p.frameRate(10);
                    this.animationFrame++;
                    if ( this.animationFrame >= this.animationFrames ) {
                        return this.showMissionComplete();
                    }
                }

                if ( this.failed ) {
                    p.frameRate(10);
                    this.animationFrame++;
                    if ( this.animationFrame >= this.animationFrames ) {
                        return this.showGameOver();
                    }
                }

                drawCoordinateSystem('game');
                for ( var i = 0; i < level.interactors.length; i++ ) {
                    level.interactors[i].display();
                }
                this.hero.display();

                p.image(this.fuelTankImg, p.width - 75, p.height - 25, 40, 40);
                context.font = "20px Architects Daughter";
                words(p.max(0, this.hero.fuel), p.round(p.width - 50), p.round(p.height - 35));
            }
        };

        var SpaceLevel = function () {
            this.images = {
                'background': "/assets/img/instructions/background-space.png",
                'fuelTankImg': "/assets/img/instructions/fuel-tank2.png",
                'completeAnimation': "/assets/img/instructions/boy-space-happy",
                'failAnimation': "/assets/img/instructions/boy-space",
                'hero': "/assets/img/instructions/shuttle.png",
                'heroBroken': "/assets/img/instructions/broken3.png",
                'heroBrokenAnimation': "/assets/img/instructions/broken",
                'mothership': "/assets/img/instructions/mothership.png",
                'mothershipComplete': "/assets/img/instructions/mother-ufo.png",
                'planetList': {
                    "mercury": "/assets/img/instructions/mercury.png",
                    "venus": "/assets/img/instructions/venus.png",
                    "earth": "/assets/img/instructions/earth.png",
                    "mars": "/assets/img/instructions/mars.png",
                    "jupiter": "/assets/img/instructions/jupiter.png",
                    "saturn": "/assets/img/instructions/saturn.png",
                    "neptune": "/assets/img/instructions/neptune.png",
                    "uranus": "/assets/img/instructions/uranus.png"
                },
                'fuelTank': "/assets/img/instructions/fuel-tank.png",
                'asteroid': "/assets/img/instructions/asteroid.png",
                'alien': "/assets/img/instructions/alien.png",
                'alienDead': "/assets/img/instructions/dead-alien.png"
            };
            this.config = {
                'defaultPlanet': "earth",
                'heroBrokenAnimationLength': 3,
                'fuelLeftMessage': 'canvas-fuel-left'
            };
            this.setup = function () {
                this.$parent.setup.call(this);
            };
            this.draw = function () {
                this.$parent.draw.call(this);
            };
        };

        SpaceLevel.extend(Level);

        var WaterLevel = function () {
            this.images = {
                'background': "/assets/img/instructions/underwater-up.png",
                'fuelTankImg': "/assets/img/instructions/oxygen-tank-up.png",
                'completeAnimation': "/assets/img/instructions/diver-happy",
                'failAnimation': "/assets/img/instructions/diver-sad",
                'hero': "/assets/img/instructions/diver-up.png",
                'heroBroken': "/assets/img/instructions/diver-up-dead1.png",
                'heroBrokenAnimation': "/assets/img/instructions/diver-up-dead",
                'mothership': "/assets/img/instructions/submarine-up.png",
                'mothershipComplete': "/assets/img/instructions/submarine-diver.png",
                'planetList': {
                    "red": "/assets/img/instructions/sea-star-red-up.png",
                    "purple": "/assets/img/instructions/sea-star-purple-up.png",
                    "pink": "/assets/img/instructions/sea-star-pink-up.png",
                    "green": "/assets/img/instructions/sea-star-green-up.png"
                },
                'fuelTank': "/assets/img/instructions/oxygen-up.png",
                'asteroid': "/assets/img/instructions/sea-urchin-up.png",
                'alien': "/assets/img/instructions/octopus-up.png",
                'alienDead': "/assets/img/instructions/octopus-up-dead.png"
            };
            this.config = {
                'defaultPlanet': "red",
                'heroBrokenAnimationLength': 2,
                'fuelLeftMessage': 'canvas-oxygen-left'
            };
            this.setup = function () {
                this.$parent.setup.call(this);
            };
            this.draw = function () {
                this.$parent.draw.call(this);
            };
        };

        WaterLevel.extend(Level);

        var Interactor = {
            xpos: 0,
            ypos: 0,
            size: 40,
            orientation: 0,
            visited: false,
            img: '',
            init: function (x, y) {
                this.xpos = x;
                this.ypos = y;
                level.interactors.push(this);
            },
            getXpos: function () {
                return this.xpos;
            },
            setXpos: function (x) {
                this.xpos = x;
            },
            getYpos: function () {
                return this.ypos;
            },
            setYpos: function (y) {
                this.ypos = y;
            },
            interacts: function () {
                return (p.dist(this.xpos, this.ypos, level.hero.getXpos(), level.hero.getYpos()) < 30);
            }
        };

        var Hero = function () {
            this.draw = true;
            this.size = 60;
            this.command = [];
            this.parameter = [];
            this.init = function (x, y, fuel) {
                fuel = typeof fuel !== 'undefined' ? fuel : 1000;
                this.xpos = x;
                this.ypos = y;
                this.xposArray = [x];
                this.yposArray = [y];
                this.planetsToVisit = 0;
                this.orientation = 0;
                this.fuel = fuel;
                this.dead = false;
                this.img = p.loadImage(level.images.hero);
                this.brokenUfo = p.loadImage(level.images.heroBroken);
                this.brokenUfoAnimation = new Animation(level.images.heroBrokenAnimation, level.config.heroBrokenAnimationLength);
            };
            this.display = function () {
                if ( !this.draw ) {
                    return;
                }
                if ( this.dead && !level.completed ) {
                    var fallDistance = p.pow(p.max(0, level.animationFrame - 10), 2);
                    if ( level.animationFrame < 10 ) {
                        this.brokenUfoAnimation.display(this.xpos, this.ypos, this.size, this.size);
                    } else {
                        p.image(this.brokenUfo, this.xpos, this.ypos - fallDistance, this.size, this.size);
                    }
                }
                for ( var i = 0; i < this.xposArray.length; i++ ) {
                    p.stroke(255);
                    p.strokeWeight(3);
                    p.point(this.xposArray[i], this.yposArray[i]);
                }
                if ( level.completed || level.failed ) {
                    return;
                }
                p.image(this.img, this.xpos, this.ypos, this.size, this.size);
                this.executeCommand();
            };
            this.storeCommand = function (command, parameter) {
                this.command.push(command);
                this.parameter.push(parameter);
            };
            this.executeCommand = function () {
                if ( !this.command.length ) {
                    return;
                }
                if ( this.parameter[0] === 0 ) {
                    this.parameter.shift();
                    this.command.shift();
                    if ( !this.command.length ) {
                        //this.die();
                    }
                    return;
                }
                if ( this.command[0] === "move" ) {
                    this.move();
                    this.parameter[0] = this.parameter[0] - 1;
                }

                if ( this.command[0] === "turn" ) {
                    this.turn(this.parameter[0]);
                    this.parameter[0] = 0;
                }

                if ( this.fuel < 0 ) {
                    this.die();
                }

            };
            this.move = function () {
                if ( this.orientation === 0 ) {
                    this.ypos++;
                }
                if ( this.orientation === 90 ) {
                    this.xpos++;
                }
                if ( this.orientation === 180 ) {
                    this.ypos--;
                }
                if ( this.orientation === 270 ) {
                    this.xpos--;
                }

                this.xposArray.push(this.xpos);
                this.yposArray.push(this.ypos);

                this.fuel--;
            };
            this.turn = function (degrees) {
                this.orientation += degrees;
                this.orientation = this.orientation % 360;

                this.fuel -= 0;
            };
            this.addFuel = function (fuel) {
                this.fuel += fuel;
            };
            this.die = function () {
                this.dead = true;
                level.fail();
            };
        };

        Hero.extend(Interactor);

        /**
         * Mothership
         * The mothership is the target.
         */
        var Mothership = function () {
            this.width = 60;
            this.height = 100;
            this.init = function (x, y) {
                this.img = p.loadImage(level.images.mothership);
                this.imgComplete = p.loadImage(level.images.mothershipComplete);
                this.$parent.init.call(this, x, y);
            };
            this.display = function () {
                if ( this.interacts() && level.planetsToVisit === 0 ) {
                    level.complete();
                    var flyDistance = p.pow(p.max(0, level.animationFrame - 10), 1.5);
                    p.image(this.imgComplete, this.xpos, this.ypos + flyDistance, this.width, this.height);
                } else {
                    p.image(this.img, this.xpos, this.ypos, this.width, this.height);
                }
            };
        };

        Mothership.extend(Interactor);

        /***
         * Planets
         * All planets need to be picked up before hero can enter the mothership.
         */
        var Planet = function () {
            this.init = function (x, y, type) {
                this.imgList = level.images.planetList;
                type = typeof type !== 'undefined' ? type : level.config.defaultPlanet;
                this.$parent.init.call(this, x, y);
                level.planetsToVisit++;
                this.planetSound = new Audio("/assets/audio/highUp.mp3");
                this.img = p.loadImage(this.imgList[type]);
            };
            this.display = function () {
                if ( this.interacted ) {
                    return;
                }
                if ( this.interacts() ) {
                    level.planetsToVisit--;
                    this.interacted = true;
                    this.planetSound.play();
                }
                p.image(this.img, this.xpos, this.ypos, this.size, this.size);
            };
        };

        Planet.extend(Interactor);

        var Fuel = function () {
            this.init = function (x, y, litres) {
                this.img = p.loadImage(level.images.fuelTank);
                this.$parent.init.call(this, x, y);
                this.litres = litres;
                this.size = 50;
            };
            this.display = function () {
                if ( this.interacted ) {
                    return;
                }
                if ( this.interacts() ) {
                    this.interacted = true;
                    level.hero.addFuel(this.litres);
                }
                p.image(this.img, this.xpos, this.ypos, this.size, this.size);
                context.font = "12px Architects Daughter";
                words(this.litres, this.xpos - 12, this.ypos);
            };
        };

        Fuel.extend(Interactor);

        var Asteroid = function () {
            this.init = function (x, y) {
                this.img = p.loadImage(level.images.asteroid);
                this.$parent.init.call(this, x, y);
            };
            this.display = function () {
                if ( this.interacts() ) {
                    level.hero.die();
                }
                p.image(this.img, this.xpos, this.ypos, this.size, this.size);
            };
        };

        Asteroid.extend(Interactor);

        var Wall = function () {
            this.init = function (x1, y1, x2, y2) {
                this.xmin = x1;
                this.xmax = x2;
                this.ymin = y1;
                this.ymax = y2;
                if ( this.ymax > this.ymin ) {
                    this.length = this.ymax - this.ymin;
                    this.vertical = true;
                } else {
                    this.length = this.xmax - this.xmin;
                    this.vertical = false;
                }
                this.img = p.loadImage("/assets/img/instructions/spacewall.png");
                this.imgLeft = p.loadImage("/assets/img/instructions/spacewall1.png");
                this.imgMiddle = p.loadImage("/assets/img/instructions/spacewall2.png");
                this.imgRight = p.loadImage("/assets/img/instructions/spacewall3.png");
                this.imgBottom = p.loadImage("/assets/img/instructions/spacewall-vertical3.png");
                this.imgVerticalMiddle = p.loadImage("/assets/img/instructions/spacewall-vertical2.png");
                this.imgTop = p.loadImage("/assets/img/instructions/spacewall-vertical1.png");
                this.$parent.init.call(this, x1, y1);
            };

            this.display = function () {
                if ( this.interacts() ) {
                    level.hero.die();
                }
                p.imageMode(p.CORNER);
                var i, numberOfMiddlePieces = p.max(0, p.floor((this.length - 2 * this.size) / this.size));
                if ( this.vertical ) {
                    p.image(this.imgBottom, this.xmin - this.size / 2, this.ymin, this.size, this.size);
                    for ( i = 0; i < numberOfMiddlePieces; i++ ) {
                        p.image(this.imgVerticalMiddle, this.xmin - this.size / 2, this.ymin + (i + 1) * this.size, this.size, this.size);
                    }
                    p.image(this.imgTop, this.xmin - this.size / 2, this.ymin + (numberOfMiddlePieces + 1) * this.size, this.size, this.size);
                } else {
                    p.image(this.imgLeft, this.xmin, this.ymin - this.size / 2, this.size, this.size);
                    for ( i = 0; i < numberOfMiddlePieces; i++ ) {
                        p.image(this.imgMiddle, this.xmin + (i + 1) * this.size, this.ymin - this.size / 2, this.size, this.size);
                    }
                    p.image(this.imgRight, this.xmin + (numberOfMiddlePieces + 1) * this.size, this.ymin - this.size / 2, this.size, this.size);
                }
                p.imageMode(p.CENTER);
            };

            this.interacts = function () {
                if ( this.vertical === true ) {
                    return (this.xmin - this.size / 2 < level.hero.getXpos() &&
                    level.hero.getXpos() < this.xmax + this.size / 2 &&
                    this.ymin < level.hero.getYpos() &&
                    level.hero.getYpos() < this.ymax);
                } else {
                    return (this.xmin < level.hero.getXpos() &&
                    level.hero.getXpos() < this.xmax &&
                    this.ymin - this.size / 2 < level.hero.getYpos() &&
                    level.hero.getYpos() < this.ymax + this.size / 2);
                }

            };
        };

        Wall.extend(Interactor);

        var Alien = function () {
            this.init = function (x1, y1, x2, y2) {
                this.$parent.init.call(this, x1, y1);
                this.xmin = p.min(x1, x2);
                this.xmax = p.max(x1, x2);
                this.ymin = p.min(y1, y2);
                this.ymax = p.max(y1, y2);
                this.img = p.loadImage(level.images.alien);
                this.imgDead = p.loadImage(level.images.alienDead);
                if ( this.ymax > this.ymin ) {
                    this.orientation = 0;
                } else {
                    this.orientation = 90;
                }
            };
            this.display = function () {
                if ( level.completed === true ) {
                    var fallDistance = p.pow(p.max(0, level.animationFrame - 10), 2);
                    p.image(this.imgDead, this.xpos, this.ypos - fallDistance, this.size, this.size);
                } else {
                    p.image(this.img, this.xpos, this.ypos, this.size, this.size);
                    this.move();
                    if ( this.interacts() ) {
                        level.hero.die();
                    }
                }
            };
            this.move = function () {
                if ( this.orientation === 0 ) {
                    this.ypos++;
                    if ( this.ypos === this.ymax ) {
                        this.turn();
                    }
                }
                if ( this.orientation === 90 ) {
                    this.xpos++;
                    if ( this.xpos === this.xmax ) {
                        this.turn();
                    }
                }
                if ( this.orientation === 180 ) {
                    this.ypos--;
                    if ( this.ypos === this.ymin ) {
                        this.turn();
                    }
                }
                if ( this.orientation === 270 ) {
                    this.xpos--;
                    if ( this.xpos === this.xmin ) {
                        this.turn();
                    }
                }
            };
            this.turn = function () {
                this.orientation += 180;
                this.orientation = this.orientation % 360;
            };
        };

        Alien.extend(Interactor);

        /*
         * The following are functions that can be called
         * from User to play the level. These should be the only accessible methods.
         */
        function move(distance) {
            level.hero.storeCommand("move", distance);
        }

        move = validate.decorate(move, "move");

        function turn(degrees) {
            level.hero.storeCommand("turn", degrees);
        }

        turn = validate.decorate(turn, "turn");

        /*
         * The following are functions that can be called
         * to construct the level. These should be the only accessible methods.
         */
        function shuttle(x, y, fuel) {
            level.hero.init(x, y, fuel);
            level.hero.draw = true;
        }

        shuttle = validate.decorate(shuttle, "shuttle");

        function diver(x, y, oxygen) {
            shuttle(x, y, oxygen);
        }

        diver = validate.decorate(diver, "diver");


        function mothership(x, y) {
            var m = new Mothership();
            m.init(x, y);
        }

        mothership = validate.decorate(mothership, "mothership");

        function submarine(x, y) {
            mothership(x, y);
        }

        submarine = validate.decorate(submarine, "submarine");


        function planet(x, y, type) {
            var p = new Planet();
            p.init(x, y, type);
        }

        planet = validate.decorate(planet, "planet");

        function star(x, y, type) {
            var p = new Planet();
            p.init(x, y, type);
        }

        star = validate.decorate(star, "star");


        function asteroid(x, y) {
            var a = new Asteroid();
            a.init(x, y);
        }

        asteroid = validate.decorate(asteroid, "asteroid");

        function urchin(x, y) {
            asteroid(x, y);
        }

        urchin = validate.decorate(urchin, "urchin");


        function wall(x1, y1, x2, y2) {
            var w = new Wall();
            w.init(x1, y1, x2, y2);
        }

        wall = validate.decorate(wall, "wall");


        function alien(x1, y1, x2, y2) {
            var a = new Alien();
            a.init(x1, y1, x2, y2);
        }

        alien = validate.decorate(alien, "alien");

        function octopus(x1, y1, x2, y2) {
            alien(x1, y1, x2, y2);
        }

        octopus = validate.decorate(octopus, "octopus");


        function fuel(x, y, litres) {
            var f = new Fuel();
            f.init(x, y, litres);
        }

        fuel = validate.decorate(fuel, "fuel");

        function oxygen(x, y, litres) {
            fuel(x, y, litres);
        }

        oxygen = validate.decorate(oxygen, "oxygen");

        function noShuttle() {
            level.hero.draw = false;
        }

        /**
         * In response to User Keys
         */
        $canvasWrapper.on('keydown', function (event) {
            if ( event.keyCode === 80 ) {
                p.pause();
            }
            if ( event.keyCode === 77 ) {
                // p.mute();
            }
        });

        p.pause = function () {
            if ( !level.paused ) {
                level.paused = true;
                p.noLoop();
            } else {
                level.paused = false;
                p.loop();
            }
        };

        p.mouseMoved = function () {
            if ( level.failed || level.completed ) {
                if ( p.mouseX > p.width / 2 - 150 &&
                    p.mouseX < p.width / 2 + 150 &&
                    p.height - p.mouseY > 50 &&
                    p.height - p.mouseY < 100 ) {
                    return p.cursor(p.HAND);
                }
            }
            p.cursor(p.ARROW);
        };

        p.mousePressed = function () {
            if ( level.completed ) {
                if ( !progress.isOpen() ) {
                    progress.next();
                }
                return;
            }
            if ( level.failed ) {
                p.preview();
            }
        };

        p.preview = function () {
            level.setup();
            level.hero.command = [];
            level.hero.parameter = [];
        };

        function words(text, x, y) {
            p.pushMatrix();
            p.translate(x, y);
            p.scale(1, -1);
            p.text(text, 0, 0);
            p.popMatrix();
        }

        /**
         * Animation helper function
         *
         * @param imagePrefix
         * @param count
         * @constructor
         */
        function Animation(imagePrefix, count) {
            this.imageCount = count;
            this.images = [this.imageCount];
            this.frame = 0;
            for ( var i = 1; i <= this.imageCount; i++ ) {
                this.filename = imagePrefix + i + ".png";
                this.images[i - 1] = p.loadImage(this.filename);
            }
            this.display = function (x, y, width, height) {
                this.frame = (this.frame + 1) % this.imageCount;
                p.image(this.images[this.frame], x, y, width, height);
            };
        }
    }

    function testSketch(processing) {

        p = processing;

        var draw;

        p.setup = function () {
            p.size($canvasWrapper.width(), $canvasWrapper.height());
            p.scale(1, -1);
            p.translate(0, -p.height);
        };

        try {
            eval(code.getUserCode());
        } catch ( error ) {
            return handleError(error);
        }

        p.draw = function () {
            p.scale(1, -1);
            p.translate(0, -p.height);
            p.strokeWeight(4);
            if ( typeof draw === 'function' ) {
                try {
                    draw();
                } catch ( error ) {
                    return handleError(error);
                }
            }
        };
    }

    function point(x, y) {
        p.point(x, y);
    }

    point = validate.decorate(point, "point");

    var line = function (x1, y1, x2, y2) {
        //p.line(x1* p.width, y1*p.height, x2* p.width, y2* p.height);
        p.line(x1, y1, x2, y2);
    };
    line = validate.decorate(line, "line");

    function rect(x1, y1, width, height) {
        p.rect(x1, y1, width, height);
    }

    rect = validate.decorate(rect, "rect");

    function triangle(x1, y1, x2, y2, x3, y3) {
        p.triangle(x1, y1, x2, y2, x3, y3);
    }

    triangle = validate.decorate(triangle, "triangle");

    function ellipse(x1, y1, width, height) {
        p.ellipse(x1, y1, width, height);
    }

    ellipse = validate.decorate(ellipse, "ellipse");

    function fill(r, g, b) {
        p.fill(r, g, b);
    }

    fill = validate.decorate(fill, "fill");

    function stroke(r, g, b) {
        p.stroke(r, g, b);
    }

    stroke = validate.decorate(stroke, "stroke");

    function background(r, g, b) {
        switch ( r ) {
            case 'red':
                p.background(255, 0, 0);
                break;
            case 'green':
                p.background(0, 255, 0);
                break;
            case 'blue':
                p.background(0, 0, 255);
                break;
            case 'white':
                p.background(255, 255, 255);
                break;
            case 'black':
                p.background(0, 0, 0);
                break;
            default:
                p.background(r, g, b);
        }
    }

    background = validate.decorate(background, "background");

    function noFill() {
        p.noFill();
    }

    noFill = validate.decorate(noFill, "noFill");

    function noStroke() {
        p.noStroke();
    }

    noStroke = validate.decorate(noStroke, "noStroke");

    function textFont(font, size) {
        switch ( font ) {
            case Jellybean:
                context.font = size + "px Cabin Sketch Bold";
                break;
            case Bubblegum:
                context.font = size + "px Lobster";
                break;
            case Lollipop:
                context.font = size + "px Comic Sans MS";
                break;
            case Cookie:
                context.font = size + "px Luckiest Guy";
                break;
            default:
            //
        }
    }

    textFont = validate.decorate(textFont, "textFont");

    function words(text, x, y) {
        p.pushMatrix();
        p.translate(x, y);
        p.scale(1, -1);
        p.text(text, 0, 0);
        p.popMatrix();
    }

    words = validate.decorate(words, "words");

    function nofill() {
        error.throw({
            name: "ReferenceError",
            undefined: "nofill",
            hint: "noFill"
        });
    }

    function nostroke() {
        error.throw({
            name: "ReferenceError",
            undefined: "nostroke",
            hint: "noStroke"
        });
    }

    function textfont() {
        error.throw({
            name: "ReferenceError",
            undefined: "textfont",
            hint: "textFont"
        });
    }

    /**
     * Draw the Coordinate System
     */
    function dasfafsg() {
        var i;


        for ( i = 1; i < 11; i++ ) {
            p.strokeWeight(1);
            p.line(i * 100, 0, i * 100, 8);
            p.pushMatrix();
            p.translate(i * 100 - 10, 15);
            p.scale(1, -1);
            p.text(i * 100, 0, 0);
            p.popMatrix();
            p.strokeWeight(0.2);
            p.line(i * 100, 30, i * 100, 1000);
        }

        p.strokeWeight(0.2);
        for ( i = 1; i < 21; i++ ) {
            p.line(i * 50, 0, i * 50, 1000);
        }

        p.strokeWeight(1);
        for ( i = 1; i < 11; i++ ) {
            p.strokeWeight(1);
            p.line(0, i * 100, 8, i * 100);
            p.pushMatrix();
            p.translate(15, i * 100 - 5);
            p.scale(1, -1);
            p.text(i * 100, 0, 0);
            p.popMatrix();
            p.strokeWeight(0.2);
            p.line(30, i * 100, 1000, i * 100);
        }

        p.strokeWeight(0.2);
        for ( i = 1; i < 21; i++ ) {
            p.line(0, i * 50, 1000, i * 50);
        }
    }


    /**
     * Draw the Coordinate System
     */
    function drawCoordinateSystem(theme) {
        var i;

        if ( theme === 'dark' ) {
            p.background(0, 0, 60);
            p.fill(255);
            p.stroke(95);
        } else if (theme === 'game') {
            p.fill(255);
            p.stroke(95);
        } else {
            p.background(246);
            p.fill(0);
            p.stroke(200);
        }

        p.textFont("sans serif", 12);

        for ( i = 1; i < 11; i++ ) {
            p.strokeWeight(2);
            p.line(i * 100, 0, i * 100, 8);
            p.pushMatrix();
            p.translate(i * 100 - 10, 15);
            p.scale(1, -1);
            p.text(i * 100, 0, 0);
            p.popMatrix();
            p.strokeWeight(1);
            p.line(i * 100, 30, i * 100, 1000);
        }

        for ( i = 1; i < 11; i++ ) {
            p.strokeWeight(0.5);
            p.line(i * 100 - 50, 0, i * 100 - 50, 1000);
        }

        for ( i = 1; i < 11; i++ ) {
            p.strokeWeight(2);
            p.line(0, i * 100, 8, i * 100);
            p.pushMatrix();
            p.translate(12, i * 100 - 4);
            p.scale(1, -1);
            p.text(i * 100, 0, 0);
            p.popMatrix();
            p.strokeWeight(1);
            p.line(35, i * 100, 1000, i * 100);
        }

        for ( i = 1; i < 11; i++ ) {
            p.strokeWeight(0.5);
            p.line(0, i * 100 - 50, 1000, i * 100 - 50);
        }
    }

    /*
     function preloadimages(images) {
     var newImages = [],
     loadedImages = 0,
     postaction = function () {
     },
     array = (typeof images !== "object") ? [images] : images;

     function imageloadpost() {
     loadedImages++;
     if ( loadedImages === array.length ) {
     postaction(newImages);
     }
     }

     for ( var i = 0; i < array.length; i++ ) {
     newImages[i] = new Image();
     newImages[i].src = array[i];
     newImages[i].onload = function () {
     imageloadpost();
     };
     newImages[i].onerror = function () {
     imageloadpost();
     };
     }
     return {
     done: function (f) {
     postaction = f || postaction;
     }
     };
     }
     */

    return {
        init: init,
        getCode: getCode
    };

})
(jQuery);