/* This file contains the code module for interacting with the user code
 * It adheres to the revealing module pattern and only makes a few function available in public API
 ========================================================================================================= */

/*global jQuery, Processing, progress, sketch */
var canvas = (function ($, Processing, progress, sketch) {
    "use strict";

    var $canvas,
        $canvasWrapper,
        mySketch;

    function init() {
        cacheDom();
        bindEvents();

        if ($canvas.length) {
            render();
        }
    }

    function cacheDom() {
        $canvasWrapper = $('#canvas');
        $canvas = $('#processing-canvas');
    }

    function bindEvents() {
        //
    }

    function render() {
        // needed to crush the last processing instance
        Processing.reload();

        mySketch = new Processing($canvas[0], sketch.getCode());

        if (progress.courseId === 3 && !code.isSubmitted()) {
            mySketch.preview();
        }
    }

    function highscore(score) {
        ajax.interact('attempted', 'project', $('.project-page').data('project-id'), score);
    }

    function refresh() {
        if ( typeof mySketch !== 'undefined' ) {
            mySketch.setup();
        }
    }

    function getPreview() {
        return $canvasWrapper.find('#processing-canvas')[0].toDataURL();
    }

    function getSketch() {
        return mySketch;
    }

    return {
        init: init,
        render: render,
        refresh: refresh,
        getPreview: getPreview,
        getSketch: getSketch,
        highscore: highscore
    };

})(jQuery, Processing, progress, sketch);