/*
 *  See API of Bootstrap Tour at http://bootstraptour.com/api/
 */

/*global Tour, jQuery, lang */
var tour = (function (Tour, $, lang) {
    "use strict";

    var tour,
        type,
        started = false,
        ended = false,
        data = {
            name: null
        },
        options = {
            name: 'introduction-tour',
            backdrop: true,
            container: 'body',
            backdropContainer: 'body',
            backdropPadding: '0 2 2 0',
            keyboard: false,
            orphan: true,
            storage: false,
            // storage: window.localStorage,
            template: "<div class='popover tour tour-popover'>\
                        <div class='arrow'></div>\
                        <div class='popover-title-container'>\
                            <h2 class='popover-title'></h2>\
                            <div class='popover-close' data-role='end'>&times;</div>\
                        </div>\
                        <div class='popover-content'></div>\
                        <div class='popover-navigation'>\
                            <button class='btn btn-primary btn-funky' data-role='next'>" + lang.trans('tour-next') + " »</button>\
                             <button class='btn btn-primary btn-funky' data-role='prev'>«</button>\
                        </div>\
                    </div>",
            onStart: function () {
                started = true;
            },
            onEnd: function () {
                ended = true;
            }
        },
        templateNoNav = "<div class='popover tour tour-popover'>\
                        <div class='arrow'></div>\
                        <div class='popover-title-container'>\
                            <h2 class='popover-title'></h2>\
                            <div class='popover-close' data-role='end'>&times;</div>\
                        </div>\
                        <div class='popover-content'></div>\
                        <div class='popover-navigation'>\
                        </div>\
                    </div>",
        templateDisabledNext = "<div class='popover tour tour-popover'>\
                        <div class='arrow'></div>\
                        <div class='popover-title-container'>\
                            <h2 class='popover-title'></h2>\
                            <div class='popover-close' data-role='end'>&times;</div>\
                        </div>\
                        <div class='popover-content'></div>\
                        <div class='popover-navigation'>\
                            <button class='btn btn-primary btn-funky disabled' disabled='disabled' data-role='next'>" + lang.trans('tour-next') + " »</button>\
                             <button class='btn btn-primary btn-funky' data-role='prev'>«</button>\
                        </div>\
                    </div>",
        templateLast = "<div class='popover tour tour-popover'>\
                        <div class='arrow'></div>\
                        <div class='popover-title-container'>\
                            <h2 class='popover-title'></h2>\
                            <div class='popover-close' data-role='end'>&times;</div>\
                        </div>\
                        <div class='popover-content'></div>\
                        <div class='popover-navigation'>\
                            <button class='btn btn-primary btn-funky' data-role='end'>" + lang.trans('tour-next') + " »</button>\
                            <button class='btn btn-primary btn-funky' data-role='prev'>«</button>\
                        </div>\
                    </div>",
        firstSteps = [
            {
                title: lang.trans('tour-1-title'),
                content: "<div class='tour-img'><img src='/assets/img/tour/1-hey.png' alt='tour YOU++ hey!'></div>" +
                "<p class='tour-text'>" + lang.trans('tour-1-content') + "</p>"
            },
            {
                element: ".js-instructions-wrapper",
                placement: 'right',
                title: lang.trans("tour-2-title"),
                content: "<div class='tour-img'><img src='/assets/img/tour/2-instructions.png' alt='tour YOU++ instructions!'></div>" +
                "<p class='tour-text'>" + lang.trans("tour-2-content") + "</p>",
                onShow: function () {
                    $('#btn-hint').prop("disabled", true);
                    instruction.scrollDown();
                },
                onHide: function () {
                    $('#btn-hint').prop("disabled", false);
                    instruction.scrollUp();
                }
            },
            {
                element: ".editor-wrapper .CodeMirror",
                placement: 'right',
                title: lang.trans("tour-3-title"),
                content: "<div><img src='/assets/img/tour/3-command-field.png' alt='tour YOU++ command field!'></div>" +
                "<p class='tour-text'>" + lang.trans("tour-3-content") + "</p>"
            },
            {
                element: "#btn-submit",
                placement: 'top',
                title: lang.trans("tour-4-title"),
                content: "<div class='tour-img'><img src='/assets/img/tour/4-run.png' alt='tour YOU++ run my code!'></div>" +
                "<p class='tour-text'>" + lang.trans("tour-4-content") + "</p>",
                template: templateNoNav
            },
            {
                element: ".js-canvas-outer",
                placement: 'left',
                title: lang.trans("tour-5-title"),
                content: "<div class='tour-img'><img src='/assets/img/tour/5-results.png' alt='tour YOU++ results!'></div>" +
                "<p class='tour-text'>" + lang.trans("tour-5-content") + "</p>"
            },
            {
                element: "#btn-points",
                placement: 'bottom',
                title: lang.trans("tour-6-title"),
                content: "<div class='tour-img'><img src='/assets/img/tour/6-points.png' alt='tour YOU++ points!'></div>" +
                "<p class='tour-text'>" + lang.trans("tour-6-content") + "</p>",
                onShow: function () {
                    $("#btn-points").off();
                },
                onHide: function () {
                    $("#btn-points").on("click", function () {
                        $('#points-modal').modal();
                    });
                }
            },
            {
                element: "#btn-next",
                placement: 'top',
                title: lang.trans("tour-7-title"),
                template: templateNoNav,
                content: "<div class='tour-img'><img src='/assets/img/tour/8-previous-next.png' alt='tour YOU++ next task!'></div>" +
                "<p class='tour-text'>" + lang.trans("tour-7-content") + "</p>"
            }
        ],
        secondSteps = [
            {
                title: lang.trans("tour-8-title"),
                content: "<img src='/assets/img/tour/1-hey.png' alt='tour YOU++ hey!'>" +
                "<p class='tour-text'>" + lang.trans("tour-8-content") + "</p>"
            },
            {
                element: "#btn-hint",
                placement: 'top',
                title: lang.trans("tour-9-title"),
                content: "<img height='200' src='/assets/img/tour/7-hints.png' alt='tour YOU++ hint!'>" +
                "<p class='tour-text'>" + lang.trans("tour-9-content") + "</p>",
                onShow: function () {
                    $('#btn-hint').prop("disabled", true);
                    instruction.scrollDown();
                },
                onHide: function () {
                    $('#btn-hint').prop("disabled", false);
                    instruction.scrollUp();
                }
            },
            {
                element: ".js-btn-reset",
                placement: 'top',
                title: lang.trans("tour-10-title"),
                content: "<img height='200' src='/assets/img/tour/11-reset.png' alt='tour YOU++ reset!'>" +
                "<p class='tour-text'>" + lang.trans("tour-10-content") + "</p>",
                onShow: function () {
                    $('#btn-hint').prop("disabled", true);
                },
                onHide: function () {
                    $('#btn-hint').prop("disabled", false);
                }
            },
            {
                element: ".js-tour-progress",
                placement: 'bottom',
                title: lang.trans("tour-11-title"),
                content: "<img src='/assets/img/tour/9-progress.png' alt='tour YOU++ progress!'>" +
                "<p class='tour-text'>" + lang.trans("tour-11-content", { 'part': '6' }) + "</p>"
            },
            {
                title: lang.trans("tour-13-title"),
                duration: 1500,
                template: templateNoNav,
                content: "<img src='/assets/img/tour/1-hey.png' alt='tour YOU++ awesome!'>" +
                "<p class='tour-text'>" + lang.trans("tour-13-content") + "</p>"
            }
        ],
        onboardingSteps = [
            {
                title: lang.trans('tour-onboarding-1-title'),
                content: "<div class='tour-img'><img src='/assets/img/tour/1-hey.png' alt='tour YOU++ hey!'></div>" +
                "<p class='tour-text'>" + lang.trans('tour-onboarding-1-content') + "</p>"
            },
            {
                element: "#js-editor",
                placement: 'right',
                title: lang.trans("tour-onboarding-2-title"),
                content: "<img height='200' src='/assets/img/tour/7-hints.png' alt='tour YOU++ hint!'>" +
                "<p class='tour-text'>" + lang.trans("tour-onboarding-2-content") + "</p>"
            },
            {
                element: "#canvas",
                placement: 'left',
                title: lang.trans("tour-onboarding-3-title"),
                content: "<img height='200' src='/assets/img/tour/7-hints.png' alt='tour YOU++ hint!'>" +
                "<p class='tour-text'>" + lang.trans("tour-onboarding-3-content") + "</p>"
            },
            {
                element: "#js-editor",
                placement: 'right',
                title: lang.trans("tour-onboarding-4-title"),
                content: "<img height='200' src='/assets/img/tour/7-hints.png' alt='tour YOU++ hint!'>" +
                "<p class='tour-text'>" + lang.trans("tour-onboarding-4-content") + "</p>",
                onShown: function () {
                    disableNext();
                    code.reset();
                    var editor = mirror.getEditor();
                    editor.focus();
                    editor.setCursor({line: 0, ch: 17});
                    editor.on("change", enableNext);
                }
            },
            {
                element: "#btn-submit",
                placement: 'top',
                title: lang.trans("tour-onboarding-5-title"),
                template: templateDisabledNext,
                content: "<img height='200' src='/assets/img/tour/7-hints.png' alt='tour YOU++ hint!'>" +
                "<p class='tour-text'>" + lang.trans("tour-onboarding-5-content") + "</p>"
            },
            {
                element: "#canvas",
                placement: 'left',
                title: lang.trans("tour-onboarding-6-title"),
                content: "<img height='200' src='/assets/img/tour/7-hints.png' alt='tour YOU++ hint!'>" +
                "<p class='tour-text'>" + lang.trans("tour-onboarding-6-content") + "</p>"
            },
            {
                element: "#input-username",
                placement: 'top',
                title: lang.trans("tour-onboarding-7-title"),
                content: "<img height='200' src='/assets/img/tour/7-hints.png' alt='tour YOU++ hint!'>" +
                "<p class='tour-text'>" + lang.trans("tour-onboarding-7-content") + "</p>",
                onShown: function () {
                    disableNext();
                    var $input = $('#input-username');
                    $input.focus();
                    $input.on("input", enableNext);
                },
                onNext: function (tour) {
                    var nameProvided = $('#input-username').val();
                    if ( $.trim(nameProvided) !== "" ) {
                        data.name = nameProvided;
                    }
                }
            },
            {
                element: "#btn-save",
                placement: 'top',
                template: templateLast,
                title: lang.trans("tour-onboarding-8-title"),
                content: function () {
                    return "<img height='200' src='/assets/img/tour/7-hints.png' alt='tour YOU++ hint!'>" +
                        "<p class='tour-text'>" + ((data.name === null) ? lang.trans("tour-onboarding-8-content") : lang.trans("tour-onboarding-8-content-name", { name: data.name })) + "</p>";
                }
            }
        ],
        lookup = {
            'first': firstSteps,
            'second': secondSteps,
            'onboarding': onboardingSteps
        };

    function run(part) {
        if ( ended || window.localStorage.getItem('introduction-tour_end') === "yes" ) {
            window.localStorage.setItem('introduction-tour_current_step', "0");
            window.localStorage.setItem('introduction-tour_end', "no");
            ended = false;
            return;
        }
        if ( part === 'onboarding' ) {
            //disable automatially focusing next button
            Tour.prototype._focus = function () { return null; };
        }

        type = part;
        tour = new Tour(options);
        tour.addSteps(lookup[part]);
        tour.init();
        tour.start(true);
    }

    function previous() {
        tour.prev();
    }

    function next() {
        if (isRunning()) {
            tour.next();
        }
    }

    function isRunning() {
        return (started && !ended);
    }

    function isExecuted() {
        return ended;
    }

    function getType() {
        return type;
    }

    function enableNext() {
        var $next = $(".tour-popover button[data-role='next']");
        $next.prop("disabled", false);
        $next.removeClass("disabled");
    }

    function disableNext() {
        var $next = $(".tour-popover button[data-role='next']");
        $next.prop("disabled", true);
        $next.addClass("disabled");
    }

    function end() {
        tour.end();
    }

    function provide() {
        return tour;
    }

    return {
        run: run,
        previous: previous,
        next: next,
        end: end,
        type: getType,
        isRunning: isRunning,
        isExecuted: isExecuted,
        provide: provide
    };

})(Tour, jQuery, lang);
